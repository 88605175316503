// ok

import AlertMessages from '../data/alert_messages';
import _ from 'lodash';
import PrepareHtml from './prepare_html';

const onUpdateValue = (newObj, actualSku, formFields, titleLengthMessageWasShown, language, showMessage) => {
  let objForState = {};
  if(!(newObj.type === 'two-fields-extended' && newObj.twoFieldsExtendedIteration === undefined)){
    if(newObj.className === 'sku' && _.head(newObj.value) !== actualSku){
      objForState = {
        ...objForState,
        docId: undefined, documentId: undefined, updateButtonDisabled: true
      }
    }
    var stateObjArr = formFields;
    if(!_.isEmpty(stateObjArr)){
      let objIndex = _.findIndex(stateObjArr, el => {
        if(newObj.twoFieldsExtendedIteration){
          return el.className === newObj.className && el.iteration === newObj.iteration && el.twoFieldsExtendedIteration === newObj.twoFieldsExtendedIteration;
        }
        return el.className === newObj.className && el.iteration === newObj.iteration;
      });
      if(objIndex < 0){
        stateObjArr.push(newObj);
      }else{
        stateObjArr[objIndex] = newObj;
      }
    }else{
      stateObjArr.push(newObj);
    }

    let fullNameVal = _.head(_.find(stateObjArr, el => el.className === 'product-full-name').value);
    if(fullNameVal){
      if(fullNameVal.length >= 100){
        if(!titleLengthMessageWasShown){
          showMessage(AlertMessages.messages.moreThanHundred);
          objForState = {
            ...objForState,
            titleLengthMessageWasShown: true
          }
        }
      }else if(fullNameVal.length < 100 && titleLengthMessageWasShown){
        objForState = {
          ...objForState,
          titleLengthMessageWasShown: false
        }
      }
    }else{
      objForState = {
        ...objForState,
        titleLengthMessageWasShown: false
      }
    }
    objForState = {
      ...objForState,
      form_fields: stateObjArr, buttonClicked: false,
      html: PrepareHtml(stateObjArr, language)
    }
  }
  return objForState;
}

export default onUpdateValue;