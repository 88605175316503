// ok

import fillDocument from './fill_document';

const initialState = () => {
  return {
    form_fields: [
      {className: "product-full-name", value: undefined, type: "one-field-without-ext", iteration: 0, label: ""},
      {className: "sku", value: undefined, type: "one-field-without-ext", iteration: 0, label: ""},
      {className: "product-title", value: [''], type: "two-fields", iteration: 0, label: ""},
      {className: "product-desc", value: [''], type: "two-fields", iteration: 0, label: undefined},
      {className: "product-state", value: "", type: "radio-field", iteration: 0, label: ""},
      {className: "about-numbers", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "about-spare-numbers", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "about-service-numbers", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "additional-single-parameter", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "cars-collection", fieldIndex: 0, label: "ZASTOSOWANIE", type: "cars-collection", value: []}
    ],
    values: [
      {type: 'one-field-without-ext', className: 'product-full-name', values: ['']},
      {type: 'one-field-without-ext', className: 'sku', values: ['']},
      {type: 'two-fields', className: 'product-title', values: ['']},
      {type: 'two-fields', className: 'product-desc', values: ['']},
      {type: 'radio-field', className: 'product-state', values: ['']},
      {type: 'one-field', className: 'about-numbers', values: ['']},
      {type: 'one-field', className: 'about-spare-numbers', values: ['']},
      {type: 'one-field', className: 'about-service-numbers', values: ['']},
      {type: 'two-fields-extended', className: 'additional-parameter-name', values: []},
      {type: 'one-field', className: 'additional-single-parameter', values: ['']},
      {type: 'cars-collection', className: 'cars-collection', values: []}
    ],
    buttonClicked: false,
    tabKey: 1,
    documentId: undefined,
    html: '',
    language: 'pl',
    titleLengthMessageWasShown: false,
    actualSku: undefined,
    skuLocked: false,
    documentIdGB: undefined,
    documentIdFR: undefined,
    documentIdDE: undefined,
    updateButtonDisabled: true
  }
}

const resetState = (values) => {
  return {
    form_fields: [
      {className: "product-full-name", value: undefined, type: "one-field-without-ext", iteration: 0, label: ""},
      {className: "sku", value: undefined, type: "one-field-without-ext", iteration: 0, label: ""},
      {className: "product-title", value: [''], type: "two-fields", iteration: 0, label: ""},
      {className: "product-desc", value: [''], type: "two-fields", iteration: 0, label: undefined},
      {className: "product-state", value: "", type: "radio-field", iteration: 0, label: ""},
      {className: "about-numbers", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "about-spare-numbers", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "about-service-numbers", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "additional-single-parameter", value: [''], type: "one-field", iteration: 0, label: undefined},
      {className: "cars-collection", fieldIndex: 0, label: "ZASTOSOWANIE", type: "cars-collection", value: []}
    ],
    ...fillDocument([
      ['one-field-without-ext', 'product-full-name', ['']],
      ['one-field-without-ext', 'sku', ['']],
      ['one-field', 'about-numbers', ['']],
      ['one-field', 'about-spare-numbers', ['']],
      ['one-field', 'about-service-numbers', ['']],
      ['two-fields', 'product-title', ['']],
      ['two-fields', 'product-desc', ['']],
      ['radio-field', 'product-state', ''],
      ['two-fields-extended', 'additional-parameter-name', []],
      ['one-field', 'additional-single-parameter', ['']],
      ['cars-collection', 'cars-collection', []]
    ], values),
    documentId: undefined,
    html: '',
    language: 'pl',
    titleLengthMessageWasShown: false,
    actualSku: undefined,
    skuLocked: false,
    documentIdGB: undefined,
    documentIdFR: undefined,
    documentIdDE: undefined,
    updateButtonDisabled: true
  }
}

export {initialState, resetState};