// ok

import _ from 'lodash';
import PrepareDataForSend from './prepare_data_for_send';

const prepareCollectionForSubmit = (form_fields, languages) => {
  
  const preparedFormFields = PrepareDataForSend(form_fields);
  function getMaxTwoFieldsExtendedIteration(className){
    let val = undefined;
    _.forEach(preparedFormFields.filter(el => el.className === className), o => {
        if(val){
          if(val < o.twoFieldsExtendedIteration) val = o.twoFieldsExtendedIteration;
        }else{
          val = o.twoFieldsExtendedIteration;
        }
    });
    return val;
  }

  function findInFormFields(className, additionalIterator = undefined){
    if(additionalIterator){
      return _.find(preparedFormFields, el => {
        let comparison = 
          el.className === className && 
          additionalIterator+1 === el.twoFieldsExtendedIteration;
          
        return comparison;
      });
    }else{
      return _.find(preparedFormFields, el => el.className === className);
    }
  }

  let skuStr = '';
  if(findInFormFields('sku').value !== undefined){
    skuStr = findInFormFields('sku').value[0];
  }

  let productTitleStr = '';
  if(findInFormFields('product-title').value !== undefined){
    productTitleStr = findInFormFields('product-title').value[0];
  }

  let langs = {gb: false,fr: false,de: false};
  if(languages){
    langs = languages;
  }
  
  let collection = {
    productFullName: findInFormFields('product-full-name'),
    sku: findInFormFields('sku'),
    skuStr: skuStr,
    productTitle: findInFormFields('product-title'),
    productTitleStr: productTitleStr,
    productDesc: findInFormFields('product-desc'),
    productState: findInFormFields('product-state'),
    aboutNumbers: findInFormFields('about-numbers'),
    aboutSpareNumbers: findInFormFields('about-spare-numbers'),
    aboutServiceNumbers: findInFormFields('about-service-numbers'),
    additionalParameterName: _.times(getMaxTwoFieldsExtendedIteration('additional-parameter-name'), n => findInFormFields('additional-parameter-name', n)),
    additionalParameterDesc: _.times(getMaxTwoFieldsExtendedIteration('additional-parameter-desc'), n => findInFormFields('additional-parameter-desc', n)),
    additionalSingleParameter: findInFormFields('additional-single-parameter'),
    carsCollection: findInFormFields('cars-collection'),
    ...langs
  };

  return collection;
}

export default prepareCollectionForSubmit;