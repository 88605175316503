import * as actionTypes from './actions';

const initialState = {
  currentSku: {},
  currentName: {},

  foundDocuments: [],
  skuInput: { "className" : "sku", "value" : [ "" ], "type" : "one-field-without-ext", "iteration" : 0 },
  languages: {gb: false, fr: false, de: false},
  productTitleStr: "",

  users: []
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_CURRENT_SKU:
      return {
        ...state,
        currentSku: action.currentSku
      }
    case actionTypes.SET_CURRENT_NAME:
      return {
        ...state,
        currentName: action.currentName
      }
    case actionTypes.SET_FOUND_DOCUMENTS:
      return {
        ...state,
        foundDocuments: action.foundDocuments
      }  
    case actionTypes.SET_SKU_INPUT:
      return {
        ...state,
        skuInput: action.skuInput
      }
    case actionTypes.SET_PRODUCT_TITLE_STR:
      return {
        ...state,
        productTitleStr: action.productTitleStr
      }
    case actionTypes.SET_LANGUAGES:
      console.log(action.languages);
      
      return {
        ...state,
        languages: action.languages
      }
    case actionTypes.SET_USERS:
      return {
        ...state,
        users: [...action.users]
      }  
    default:
      return state;
  }
}

export default reducer;