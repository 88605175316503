import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ReactFileReader from 'react-file-reader';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';
import API from '../api';
import Papa from 'papaparse';
import Loader from 'react-loader-spinner';

class CarsModule extends Component{

  constructor(props){
    super(props);
    this.state={
      cars: [], 
      selectedToDestroy: [],
      page: 1,
      itemsPerPage: 10,
      totalSize: 0,
    };
  }

  componentDidMount() {
    this.getCarsList().then(res => {
      this.setState({
        cars: res.data['hydra:member'],
        totalSize: res.data['hydra:totalItems']
      });
    });
  }

  getCarsList = (page = 1, sizePerPage = this.state.itemsPerPage) => {
    this.setState({preloaderShow: true});
    return new Promise((resolve, reject) => {
      
      API.get(
        `/cars?itemsPerPage=${sizePerPage}&page=${page}`
      ).then((res) => {
        this.setState({preloaderShow: false});
        resolve(res);
      })
      .catch((error) => {
        this.setState({preloaderShow: false});
        reject(error);
      });
    });
  }

  handleFiles = files => {
    let reader = new FileReader();
    let newArr = [];
    
    reader.onload = e => {
      let data = Papa.parse(reader.result).data.filter(el => el.length === 3);
      
      let promise1 = new Promise(function(resolve, reject) {
        const dataLength = data.length;
        _.forEach(data, (el, index) => {
          if(el[2]){
            API.get(
              `/cars?make=${el[0]}&model=${el[1]}`
            ).then((res) => {
              const totalItems = res.data['hydra:totalItems'];
              const member = res.data['hydra:member'];
              
              if(totalItems > 0){
                let foundCarDB = member[0];
                let engineExistInDB = _.includes(foundCarDB.engine, el[2]);
                
                if(!engineExistInDB){
                  foundCarDB.engine.push(el[2]);
                  this.setState({preloaderShow: true});
                  API.put(
                    '/cars/' + foundCarDB.id,
                    {make: foundCarDB.make, model: foundCarDB.model, engine: foundCarDB.engine},
                  ).then(res => {
                    this.setState({preloaderShow: false});
                    console.log(res);
                    if(index === dataLength-1){
                      resolve();
                    }
                  })
                  .catch(error => {
                    this.setState({preloaderShow: false});
                    console.log(error);
                  });
                }
              }else{
                let newArrCarIndex = _.findIndex(newArr, n => {
                  return n.make === el[0] && n.model === el[1];
                });
                
                if(newArrCarIndex > -1){
                  if(!_.includes(newArr[newArrCarIndex].engines, el[2])){
                    newArr[newArrCarIndex].engines.push(el[2]);
                  }
                }else{
                  newArr.push({make: el[0], model: el[1], engines: [el[2]]});
                }
                if(index === dataLength-1){
                  resolve();
                }
              }
            })
            .catch((error) => {
              console.log('Something went wrong with cars import');
              console.log(error);           
            });
          }
        });
      });

      promise1.then(() => {
        _.forEach(newArr, el => {
          if(el.make && el.model && el.engines){
            API.post(
              '/cars', 
              {make: el.make, model: el.model, engine: el.engines},
            ).then(res => {
              this.setState({preloaderShow: false});
              console.log(res);
            })
            .catch(error => {
              this.setState({preloaderShow: false});
              console.log(error);
            });
          }
        });
      });
        
    };
    reader.readAsText(files[0]);
  }

  onRowSelect(row, isSelected, e) {
    let selectedToDestroy = [...this.state.selectedToDestroy];
    let index = _.indexOf(selectedToDestroy, row.id);

    if(isSelected){
      index < 0 && selectedToDestroy.push(row.id);
    }else{
      index > -1 && _.pullAt(selectedToDestroy, index);
    }
    console.log(selectedToDestroy);
    
    this.setState({selectedToDestroy: selectedToDestroy});
  }

  onSelectAll(isSelected, rows) {
    let selectedToDestroy = [...this.state.selectedToDestroy];
    for (let i = 0; i < rows.length; i++) {
      let index = _.indexOf(selectedToDestroy, rows[i].id);
      if(isSelected){
        index < 0 && selectedToDestroy.push(rows[i].id);
      }else{
        index > -1 && _.pullAt(selectedToDestroy, index);
      }
    }
    this.setState({selectedToDestroy: selectedToDestroy});
  }

  removeSelected(){
    confirmAlert({
      title: 'Usuwanie',                        // Title dialog
      message: 'Czy jestes pewny, że chcesz usunąć zaznaczone pojazdy z bazy danych?',               // Message dialog
      confirmLabel: 'Tak',                           // Text button confirm
      cancelLabel: 'Anuluj',                             // Text button cancel
      onConfirm: () => {
        _.forEach([...this.state.selectedToDestroy], el => {
          let deletePromise = new Promise((resolve, reject) => {
            this.setState({preloaderShow: true});
            API.delete(
              '/cars/' + el
            ).then((res) => {
              console.log(res);
              this.setState({preloaderShow: false});
              resolve(el);
            })
            .catch((error) => {
              console.log(error);
              this.setState({preloaderShow: false});
              reject(error);
            });
          });

          deletePromise.then(el => {            
            let filteredCars = [...this.state.cars].filter(function(value, index, arr){
              return value.id !== el;
            });
            
            this.setState({cars: filteredCars, selectedToDestroy: []});
            this.refs.cars.cleanSelected();
          }).catch(error => {
            console.log(error);
          });
          
        });
      }
    });
  }

  displayPreloader() {
    if(this.state.preloaderShow){
      return(
        <div className='preloader-screen'>
            <Loader
              type="Oval"
              color="#00BFFF"
              height="100"
              width="100"
            />
        </div>
      );
    }
  }

  render(){
    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect.bind(this),
      onSelectAll: this.onSelectAll.bind(this)
    };

    const handlePageChange = (page) => {
      this.getCarsList(page).then(res => {
        this.setState({
          cars: res.data['hydra:member'],
          totalSize: res.data['hydra:totalItems'],
          page: page,
        });
      });
    };

    const onSizePerPageList = sizePerPage => {
      this.getCarsList(this.state.page, sizePerPage).then(res => {
        this.setState({
          cars: res.data['hydra:member'],
          totalSize: res.data['hydra:totalItems'],
          itemsPerPage: sizePerPage
        });
      });
    };
    
    const options = {
      page: this.state.page,
      sizePerPage: this.state.itemsPerPage,
      onPageChange: handlePageChange,
      sizePerPageList: [ 10, 30, 50, 100 ],
      onSizePerPageList: onSizePerPageList
    }

    return (
      <div>
        {this.displayPreloader()}
        <div className='documents translated-documents'>
          <div className='jumbotron'>
            <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
              <button className='btn btn-info'>Importuj</button>
            </ReactFileReader>
            <button onClick={e => this.removeSelected()} className='btn btn-danger btn-remove-selected-cars'>Usuń zaznaczone</button>
            <BootstrapTable 
              data={ this.state.cars } 
              options={ options }
              fetchInfo={ {dataTotalSize: this.state.totalSize} }
              selectRow={ selectRowProp } 
              ref='cars' 
              remote
              pagination
              condensed
            >
              <TableHeaderColumn dataField='id' dataSort isKey={ true } >ID pojazdu</TableHeaderColumn>
              <TableHeaderColumn dataField='make' dataSort filter={ { type: 'TextFilter', delay: 1000 } }>Marka</TableHeaderColumn>
              <TableHeaderColumn dataField='model' dataSort filter={ { type: 'TextFilter', delay: 1000 } }>Model</TableHeaderColumn>
              <TableHeaderColumn dataField='engine'>Wersje silnikowe</TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
};

export default CarsModule;
