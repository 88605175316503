export default {
  messages: {
    beforeInputEmpty: 'Wypełnij poprzednie pole',
    carAlreadyExist: 'Taki pojazd już został dodany',
    fillMakeAndModel: 'Wypełnij przynajmniej markę i model',
    skuIsRequired: 'Pole "Numer magazynowy" jest wymagane',
    fullNameIsRequired: 'Pole "Tytuł shoper" jest wymagane',
    cantRemoveBaseField: 'Nie można usunąć pola bazowego',
    htmlCopied: 'Skopiowano HTML',
    languageNotFound: 'Nie zaznaczono wersji językowej',
    languageNotUpdated: 'Nazwa dokumentu nie została przetłumaczona',
    baseLanguageVersionNotExist: 'Najpierw zapisz dokument w wersji polskiej',
    documentAlreadyExists: 'Taki dokument już istnieje',
    moreThanHundred: 'Przekroczyłeś 100 znaków',
    createsNewDocument: 'Tworzysz nowy dokument',
    skuExists: 'Dokument o takim SKU już istnieje',
    userExists: 'Wystąpił problem podczas tworzenia nowego użytkownika. Prawdopodobnie użytkownik o takim adresie email juz istnieje.',
    userCreated: 'Użytkownik został stworzony pomyślnie.',
    badCredentials: 'Błędne dane logowania',
    serverError: 'Błąd połączenia z serwerem'
  }
};
