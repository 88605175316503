import React, { Component } from "react";
import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import API from '../../api';
import AlertContainer from 'react-alert';
import AlertMessages from '../../data/alert_messages';

class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    
    if (this.refs.authForm.reportValidity()) {
      window.localStorage.removeItem("JWT");
      window.localStorage.removeItem("username");
      window.localStorage.removeItem("IRI");
      
      delete API.defaults.headers['Authorization'];
      API.post(
        '/authentication_token',
        {
          "email": this.state.email,
          "password": this.state.password
        }
      ).then(res => {
        window.localStorage.setItem("JWT", res.data.token);
        API.defaults.headers.get['Authorization'] = "Bearer " + res.data.token;
        window.localStorage.setItem("username", this.state.email);
        API.get('/app_users?email=' + this.state.email).then(res => {
          window.localStorage.setItem("IRI", '/app_users/' + res.data['hydra:member'][0].id);
          window.location.replace("/");
        }).catch(function (error) {
          console.log(error);
        });
      }).catch(error => {
        console.log(error.response);
        if(typeof error.response === 'undefined'){
          this.msg.error(AlertMessages.messages.serverError);
        }else if(error.response.data.code === 401) {
          this.msg.error(AlertMessages.messages.badCredentials);
        }
        this.setState({email: '', password: ''});
      });
    }
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit.bind(this)} ref="authForm">
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Button
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
          >
            Login
          </Button>
        </form>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
      </div>
    );
  }
}

export default Auth;