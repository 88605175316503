import React, { Component } from 'react';
import { connect } from 'react-redux';

import { initialState, resetState } from '../../helpers/initial_state';
import { Tabs, Tab } from 'react-bootstrap';
import onLanguageChange from '../../helpers/on_language_change';
import fillDocument from '../../helpers/fill_document';
import getValues from '../../helpers/get_values';
import onDeleteValue from '../../helpers/on_delete_value';
import onUpdateValue from '../../helpers/on_update_value';
import prepareCollectionForSubmit from '../../helpers/prepare_collection_for_submit';
import handleUpdateSubmit from '../../helpers/handle_update_submit';
import handleSubmit from '../../helpers/handle_submit';

import OneFieldModule from '../../components/one_field_module';
import OneFieldWithoutExtModule from '../../components/one_field_without_ext_module';
import TwoFieldsModule from '../../components/two_fields_module';
import TwoFieldsExtendedModule from '../../components/two_fields_extended_module';
import RadioFieldModule from '../../components/radio_field_module';
import CarsCollectionModule from '../../components/cars_collection_module';
import FieldModules from '../../data/field_modules';
import DocumentsModule from '../../components/documents_module';
import CarsModule from '../../components/cars_module';
import UsersModule from '../../components/users_module';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AlertContainer from 'react-alert';
import AlertMessages from '../../data/alert_messages';
import LanguageButtons from '../../components/language_buttons';

import * as actionTypes from '../../store/actions';

import checkToken from '../../helpers/check_token';
import NewUser from '../../components/new_user';

import Loader from 'react-loader-spinner';

import API from '../../api';

class Fagen extends Component{

  constructor(props){
    super(props);
    this.state = initialState();
  }

  componentWillUpdate(){
    checkToken();
  }

  onLanguageChange(language){
    this.setState({preloaderShow: true});
    onLanguageChange(language, this.state.actualSku, [...this.state.values])
      .then((objForState) => {
        this.setState({...objForState, preloaderShow: false});
      });
  }

  handleSubmit(e){
    this.setState({preloaderShow: true});
    handleSubmit(e, this.state.documentId, 
      [...this.state.form_fields], this.state.language, 
      [...this.state.values]).then(value => {
        let sku = this.state.actualSku;
        if(value.currentSku) {
          this.setDocuments(value.currentSku.value[0]);
        }else{
          this.setDocuments(sku);
        }
        this.setState({...value.forState, preloaderShow: false});
      }, reason => {
        this.msg.error(reason);
        this.setState({preloaderShow: false});
      });
  }

  setDocuments(sku) {
    API.get(
      '/documents?skuStr=' + sku
    ).then((res) => {
      this.props.setFoundDocuments(res.data['hydra:member']);
    }).catch((error) => {
      console.log(error);
    });
  }

  handleUpdateSubmit(){
    this.setState({preloaderShow: true});
    let language = this.state.language;
    let languages = this.props.languages;
    let docIdAPI;
    switch(language) {
      case 'pl':
        docIdAPI = this.state.documentId;
      break;
      case 'gb':
        docIdAPI = this.state.documentIdGB;
      break;
      case 'fr':
        docIdAPI = this.state.documentIdFR;
      break;
      case 'de':
        docIdAPI = this.state.documentIdDE;
      break;
    }

    handleUpdateSubmit(
      [...this.state.form_fields],
      docIdAPI,
      language,
      [...this.state.values],
      languages
    ).then(value => {
      let sku = this.state.actualSku;
      this.setState({...value.forState, preloaderShow: false});      
      
      if(value.currentSku){
        this.setDocuments(value.currentSku.value[0]);
        this.props.setCurrentSku(value.currentSku);
      }else{
        this.setDocuments(sku);
        this.props.setCurrentSku(sku);
      }
    }, reason => {
      this.setState({preloaderShow: false});
    });
  }

  prepareCollectionForSubmit(){
    return prepareCollectionForSubmit([...this.state.form_fields]);
  }

  onUpdateVal(newObj){
    this.setState(onUpdateValue(
      newObj, this.state.actualSku, this.state.form_fields, 
      this.state.titleLengthMessageWasShown, this.state.language,
      message => this.msg.error(message)
      )
    );  
  }

  onDeleteValue(className, type, extendedIteration = -1, removeAll = false){
    this.setState(onDeleteValue(className, type, extendedIteration, removeAll, [...this.state.form_fields], this.state.language));
  }

  fillDocument(arr, idAPI=undefined){
    this.setState({
      ...resetState([...this.state.values]),
      actualSku: _.head(arr[_.findIndex(arr, el => el[1] === 'sku')][2]),
      updateButtonDisabled: false,
      titleLengthMessageWasShown: true,
      ...fillDocument(arr, [...this.state.values], idAPI)
    });
  }

  handleSelectTab(tabKey){
    this.setState({tabKey: tabKey});
  }

  logOff(){
    window.localStorage.removeItem("JWT");
    window.location.replace("/");
  }

  displayPreloader() {
    if(this.state.preloaderShow){
      return(
        <div className='preloader-screen'>
            <Loader
              type="Oval"
              color="#00BFFF"
              height="100"
              width="100"
            />
        </div>
      );
    }
  }

  usersTab() {
    if(checkToken(true)) {
      return(
        <Tab eventKey={4} title="Lista użytkowników">
          <UsersModule />
        </Tab>
      );
    }
  }
  newUserTab() {
    if(checkToken(true)) {
      return(
        <Tab eventKey={5} title="Nowy użytkownik">
          <NewUser />
        </Tab>
      );
    }
  }

  render(){
    const FieldsSet = FieldModules.modules.map((field, index) => {
      switch(field.type) {
        case 'one-field':
          return <OneFieldModule buttonClicked={this.state.buttonClicked} onDeleteValue={this.onDeleteValue.bind(this)} values={getValues(field.class, field.type, [...this.state.values])} onUpdateVal={this.onUpdateVal.bind(this)} key={`${field.class}-${index}`} className={field.class} labelName={field.name} fieldType={field.type} label={field.label ? field.label : ''} />;
        case 'one-field-without-ext':
          return <OneFieldWithoutExtModule skuLocked={this.state.skuLocked} buttonClicked={this.state.buttonClicked} values={getValues(field.class, field.type, [...this.state.values])} onUpdateVal={this.onUpdateVal.bind(this)} key={`${field.class}-${index}`} className={field.class} labelName={field.name} fieldType={field.type} label={field.label ? field.label : ''} />;
        case 'two-fields':
          return <TwoFieldsModule buttonClicked={this.state.buttonClicked} values={getValues(field.class, field.type, [...this.state.values])} secondValues={getValues(field.secondClass, field.type, [...this.state.values])} onDeleteValue={this.onDeleteValue.bind(this)} onUpdateVal={this.onUpdateVal.bind(this)} key={`${field.class}-${index}`} className={field.class} labelName={field.name} secondClassName={field.secondClass} secondLabelName={field.secondName} fieldType={field.type} label={field.label ? field.label : ''} />;
        case 'radio-field':
          return <RadioFieldModule buttonClicked={this.state.buttonClicked} values={getValues(field.class, field.type, [...this.state.values])} onUpdateVal={this.onUpdateVal.bind(this)} key={`${field.class}-${index}`} className={field.class} radioTitle={field.title} bootstrapColPerElement={field.columns} radioGroupName={field.groupName} options={field.options} fieldType={field.type} label={field.label ? field.label : ''} />;
        case 'two-fields-extended':
          return <TwoFieldsExtendedModule onDeleteValue={this.onDeleteValue.bind(this)} buttonClicked={this.state.buttonClicked} values={getValues(field.class, field.type, [...this.state.values])} onUpdateVal={this.onUpdateVal.bind(this)} key={`${field.class}-${index}`} className={field.class} labelName={field.name} secondClassName={field.secondClass} secondLabelName={field.secondName} fieldType={field.type} label={field.label ? field.label : ''} />;
        case 'cars-collection':
          return <CarsCollectionModule buttonClicked={this.state.buttonClicked} values={getValues(field.class, field.type, [...this.state.values])} onUpdateVal={this.onUpdateVal.bind(this)} key={`${field.class}-${index}`} bodyOptions={field.bodyOptions} className={field.class} fieldType={field.type} label={field.label ? field.label : ''} />;
      }
    });

    const isCreateButtonDisabled = () => {
      switch(this.state.language) {
        case 'gb':
          return !!this.state.documentIdGB;
        case 'fr':
          return !!this.state.documentIdFR;
        case 'de':
          return !!this.state.documentIdDE;
      }    
    };

    return (
      <div className='container container-tabs'>
        {this.displayPreloader()}
        <div className="logged-in-as">
          <p>
            <b>Zalogowany użytkownik:</b> {window.localStorage.getItem("username")}
          </p>
          <button onClick={() => this.logOff()}>Wyloguj</button>
        </div>
        <Tabs activeKey={this.state.tabKey} onSelect={key => this.handleSelectTab(key)} id="tabs">
          <Tab eventKey={1} title="Formularz">
            <form className="form-document" onSubmit={this.handleSubmit.bind(this)}>
              <div className='main-control'>
                <button disabled={isCreateButtonDisabled()} className="btn btn-success btn-top btn-create-doc">Zapisz nowy formularz</button>
                <button type="button" disabled={this.state.updateButtonDisabled} onClick={() => this.handleUpdateSubmit()} className="btn btn-info btn-top btn-update-doc">Zaktualizuj formularz</button>
                <button type="button" onClick={() => this.setState(resetState([...this.state.values]))} className="btn btn-warning btn-top btn-clear-doc">Wyczyść formularz</button>
                <CopyToClipboard text={this.state.html} onCopy={() => this.msg.success(AlertMessages.messages.htmlCopied)}>
                  <button type="button" className="btn btn-primary btn-top">Kopiuj HTML</button>
                </CopyToClipboard>
              </div>
              <div>
                <LanguageButtons languages={this.props.languages} disabled={!this.state.documentId} language={this.state.language} onLanguageChange={this.onLanguageChange.bind(this)} />
              </div>
              {FieldsSet}
              <div className='text-center bottom-create-button-div'>
                <button className="btn btn-success btn-top btn-create-doc btn-create-doc-bottom">Zapisz nowy formularz</button>
              </div>
            </form>
            <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
          </Tab>
          <Tab eventKey={2} title="Baza produktów">
            <DocumentsModule fillDocument={(doc, idAPI) => {
              this.setState(resetState([...this.state.values]));
              this.fillDocument(doc, idAPI)
              }}
              resetState={() => {
                this.setState(resetState([...this.state.values]));
              }}
            />
          </Tab>
          <Tab eventKey={3} title="Lista samochodów">
            <CarsModule />
          </Tab>
          {this.usersTab()}
          {this.newUserTab()}
        </Tabs>
      </div>
    );
  };
  
};

const mapStateToProps = state => {
  return {
    languages: state.languages
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentSku: currentSku => dispatch({type: actionTypes.SET_CURRENT_SKU, currentSku: currentSku}),
    setSkuInput: currentSku => dispatch({type: actionTypes.SET_SKU_INPUT, currentSku: currentSku}),
    setLanguages: languages => dispatch({type: actionTypes.SET_LANGUAGES, languages: languages}),
    setFoundDocuments: foundDocuments => dispatch({type: actionTypes.SET_FOUND_DOCUMENTS, foundDocuments: foundDocuments}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fagen);