import _ from 'lodash';
import updateObjectValuesByClassAndType 
  from './update_object_values_by_class_and_type';
  
const fillDocument = (arr, stateValues, idAPI=undefined) => {
  
  let objForState = {};
  _.forEach(arr, el => {
    objForState = {
      ...objForState,
      ...updateObjectValuesByClassAndType(el[0], el[1], el[2], stateValues)
    }
  });
  if(idAPI) {
    objForState = {
      ...objForState,
      tabKey: 1,
      documentId: idAPI
    }
  }else{
    objForState = {
      ...objForState,
      tabKey: 1
    }
  }
  return objForState;
}

export default fillDocument;
