import React, {Component} from 'react';
import InputComponent from './input_component';
import _ from 'lodash';

class OneFieldWithoutExtModuleConst extends Component {
  constructor(props){
    super(props);
    this.state = {value: [''], disabled: false};
  }

  componentWillReceiveProps(nextProps){
    nextProps.className === 'sku' && this.setState({disabled: nextProps.skuLocked});
    if(nextProps.values !== this.props.values){
      this.setVal(this.prepareValue(nextProps.values));
      this.setState({value: nextProps.values});
    }
  }

  prepareValue(value){
    return {className: this.props.className, value: value, type: this.props.fieldType, iteration: 0, label: '' };
  }

  setVal(value){
    let valueM = value;
    if(_.isEmpty(_.head(valueM.value))){
      valueM.value = undefined
    }
    this.props.onUpdateVal(value);
  }

  render(){
    return (
      <div className='jumbotron'>
      <div className='row'>
      <label>{this.props.labelName}</label>
      <InputComponent disabled={this.state.disabled} buttonClicked={this.props.buttonClicked} value={[_.head([...this.state.value])]} onUpdate={this.setVal.bind(this)} key={this.props.className} className={this.props.className} fieldType={this.props.fieldType} fieldIndex={0} label={this.props.label} />
      </div>
      </div>
    );
  }
};

export default OneFieldWithoutExtModuleConst;
