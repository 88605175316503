import React, {Component} from 'react';
import ReactCountryFlag from 'react-country-flag';

class LanguageButtons extends Component{
  constructor(){
    super();
    this.state={
      value: 'pl',
    };
  }

  componentWillMount(){
    this.setState({disabled: this.props.disabled});
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      value: nextProps.language,
      disabled: nextProps.disabled
    });
  }

  onLanguageChange(e){
    let value = e.target.value;
    this.setState({value: value});
    this.props.onLanguageChange(value);
  }

  render(){
    let translated = lang => {
      switch(lang){
        case 'gb':
          if(!this.state.disabled){
            return this.props.languages.gb ?
              <i className="glyphicon glyphicon-ok ok-flag"></i> :
              <i className="glyphicon glyphicon-remove remove-flag"></i>;
          }else{
            return;
          }
        case 'fr':
          if(!this.state.disabled){
            return this.props.languages.fr ?
              <i className="glyphicon glyphicon-ok ok-flag"></i> :
              <i className="glyphicon glyphicon-remove remove-flag"></i>;
          }else{
            return;
          }
        case 'de':
          if(!this.state.disabled){
            return this.props.languages.de ?
              <i className="glyphicon glyphicon-ok ok-flag"></i> :
              <i className="glyphicon glyphicon-remove remove-flag"></i>;
          }else{
            return;
          }
      }
    }

    return (
      <div>
        <div className="country-flags">
          <div>
            <ReactCountryFlag code="PL" svg />
            <input type="radio"
              name="language-buttons"
              value="pl"
              checked={this.state.value === 'pl'}
              onChange={e=>{
                this.onLanguageChange(e);
              }}
            />
          </div>
          <div>
            <ReactCountryFlag code="GB" svg />
            <input
              type="radio"
              name="language-buttons"
              value="gb"
              disabled={this.state.disabled}
              checked={this.state.value === 'gb'}
              onChange={e=>{
                this.onLanguageChange(e);
              }}
            />
            {translated('gb')}
          </div>
          <div>
            <ReactCountryFlag code="FR" svg />
            <input
              type="radio"
              name="language-buttons"
              value="fr"
              disabled={this.state.disabled}
              checked={this.state.value === 'fr'}
              onChange={e=>{
                this.onLanguageChange(e);
              }}
            />
            {translated('fr')}
          </div>
          <div>
            <ReactCountryFlag code="DE" svg />
            <input
              type="radio"
              name="language-buttons"
              value="de"
              disabled={this.state.disabled}
              checked={this.state.value === 'de'}
              onChange={e=>{
                this.onLanguageChange(e);
              }}
            />
            {translated('de')}
          </div>
        </div>
      </div>
    );
  }
};

export default LanguageButtons;
