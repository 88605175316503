import _ from 'lodash';

const preparedDocument = doc => {
  return prepareDocumentToEdit(doc);
}

function prepareDocumentToEdit(document){
  let additionalParametersCount = document.additionalParameterName.length;
  let additionalParametersArr = [];
  _.times(additionalParametersCount, n => {
    let addParamDesc = document.additionalParameterDesc[n];
    let addParamDescValue = addParamDesc ? getValue(addParamDesc) : [''];
    additionalParametersArr.push({value: document.additionalParameterName[n].value, secondValues: addParamDescValue});
  });

  function getValue(value){
    if(value){
      return value.value ? value.value : [''];
    }else{
      return [''];
    }
  }

  function getValueForRadio(value){
    return value ? value : '';
  }

  function getCarsCollectionValue(collection){
    if(collection){
      return collection.value;
    }else{
      return [];
    }
  }

  return [
    ['one-field-without-ext', 'product-full-name', getValue(document.productFullName)],
    ['one-field-without-ext', 'sku', getValue(document.sku)],
    ['two-fields', 'product-title', getValue(document.productTitle)],
    ['two-fields', 'product-desc', getValue(document.productDesc)],
    ['radio-field', 'product-state', [getValueForRadio(document.productState.value)]],
    ['one-field', 'about-numbers', getValue(document.aboutNumbers)],
    ['one-field', 'about-spare-numbers', getValue(document.aboutSpareNumbers)],
    ['one-field', 'about-service-numbers', getValue(document.aboutServiceNumbers)],
    ['two-fields-extended', 'additional-parameter-name', additionalParametersArr],
    ['one-field', 'additional-single-parameter', getValue(document.additionalSingleParameter)],
    ['cars-collection', 'cars-collection', getCarsCollectionValue(document.carsCollection)]
  ];
}

export default preparedDocument;
