import React, {Component} from 'react';
import InputComponent from './input_component';
import AlertContainer from 'react-alert';
import AlertMessages from '../data/alert_messages';
import _ from 'lodash';

class TwoFieldsModule extends Component {

  constructor(props){
    super(props);
    this.state = { values: [''], twoFieldsInputs: [''] };
    this.addField = this.addField.bind(this);
  }

  componentWillMount(){
    this.setState({
      values: this.props.values,
      twoFieldsInputs: this.props.secondValues
    });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.buttonClicked){
      this.setVal({className: this.props.className, value: nextProps.values, type: this.props.fieldType, iteration: 0, label: '' });
      _.times(nextProps.secondValues.length, m => {
        this.setVal({className: this.props.secondClassName, value: [nextProps.secondValues[m]], type: this.props.fieldType, iteration: m, label: undefined });
      });
      this.setState({values: nextProps.values, twoFieldsInputs: nextProps.secondValues});
    }
  }

  setVal(value){
    this.props.onUpdateVal(value);
  }

  alertOptions = {
    offset: 14,
    position: 'bottom left',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  addField(){
    const thisElementsInDom = document.getElementsByClassName(this.props.secondClassName);
    if(thisElementsInDom[thisElementsInDom.length-1].value === ''){
      this.msg.error(AlertMessages.messages.beforeInputEmpty);
      return;
    }
    let inputs = [...this.state.twoFieldsInputs];
    inputs.push('');
    this.setState({ twoFieldsInputs: inputs });
  }

  removeField(){
    const inputs = [...this.state.twoFieldsInputs];
    if(inputs.length > 1){
      inputs.pop();
      console.log(this.props);
      this.props.onDeleteValue(this.props.secondClassName, this.props.fieldType, this.props.fieldIndex);
      this.setState({ twoFieldsInputs: inputs });
    }else{
      this.msg.error(AlertMessages.messages.cantRemoveBaseField);
      return;
    }
  }

  prepareInputs(){
    return _.map([...this.state.twoFieldsInputs], (el, index) => {
      return <InputComponent bold={true} buttonClicked={this.props.buttonClicked} value={[el]} onUpdate={this.setVal.bind(this)} key={`${this.props.secondClassName}-${index}`} className={`${this.props.secondClassName}`} fieldType={this.props.fieldType} fieldIndex={index} twoFieldsExtendedIndex={this.props.fieldIndex} />
    });
  }

  render(){
    return (
      <div className='jumbotron'>
      <div className='row'>
      <div className={`row ${this.props.className}`}>
      <label>{this.props.labelName}</label>
      <InputComponent buttonClicked={this.props.buttonClicked} value={[...this.state.values]} onUpdate={this.setVal.bind(this)} className={this.props.className} fieldType={this.props.fieldType} fieldIndex={0} label={this.props.label} twoFieldsExtendedIndex={this.props.fieldIndex} />
      <label>{this.props.secondLabelName}</label>
      <div>{this.prepareInputs()}</div>
      <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
      <button type="button" onClick={this.addField} className={`btn btn-success btn-add-${this.props.className}`}>
      <i className="glyphicon glyphicon-plus"></i>
      </button>
      <button type="button" onClick={this.removeField.bind(this)} className={`btn btn-danger btn-remove-${this.props.className}`}>
      <i className="glyphicon glyphicon-minus"></i>
      </button>
      </div>
      </div>
      </div>
    );
  }

};

export default TwoFieldsModule;
