import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import ReactCountryFlag from 'react-country-flag';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import PrepareDocument from '../helpers/prepare_document_to_edit';
import _ from 'lodash';
import API from '../api';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import Loader from 'react-loader-spinner';

class TextFilter extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      skuLocked: false,
      productFullNameStrLocked: false,
      users: [],
      usersLoaded: false,
      page: 1,
      itemsPerPage: 10,
      totalSize: 0,
      queryParams: {
        skuStr: '',
        productTitleStr: '',
        gb: false,
        fr: false,
        de: false,
      }
    };
    this.options = {
      defaultSortName: 'name',  // default sort column name
      defaultSortOrder: 'desc'  // default sort order
    };
  }

  setFoundDocumentsInStore() {
    this.getDocumentsList().then(res => {
      this.setState({
        totalSize: res.data['hydra:totalItems']
      });
      this.props.setFoundDocuments(res.data['hydra:member']);
    });
  }

  componentDidMount() {
    API.get('app_users').then(res => {
      this.getDocumentsList().then(res => {
        this.props.setFoundDocuments(res.data['hydra:member']);
        this.setState({
          totalSize: res.data['hydra:totalItems'],
        });
      });
      let users = _.map(res.data['hydra:member'], el => {
        return {IRI: '/app_users/' + el.id, email: el.email}
      });
      this.setState({
        users: [...users],
        usersLoaded: true
      });
    }).catch(error => {
      console.log(error.response);
    });
  }

  componentWillMount(){
    window.addEventListener('keydown', (e) => {
      let isTab2Active = 
        document.getElementById('tabs-tab-2').getAttribute('class') === 'active';

      if(isTab2Active && e.key === 'Enter'){
        let btnSku = document.getElementById('btn-sku');
        btnSku.click();
      }
    }, false);
  }

  removeDoc(row){
    confirmAlert({
      title: 'Usuwanie',                        // Title dialog
      message: 'Czy jestes pewny, że chcesz usunąć dokument z bazy danych?',               // Message dialog
      confirmLabel: 'Tak',                           // Text button confirm
      cancelLabel: 'Anuluj',                             // Text button cancel
      onConfirm: () => {
        let sku = row.sku;
        this.setState({preloaderShow: true});
        this.props.resetState();
        API.get(
          '/documents?skuStr=' + sku
        ).then(res => {
          console.log(res);
          API.delete(
            '/documents/' + _.head(res.data['hydra:member']).id
          ).then(res => {
            this.setState({preloaderShow: false});
            console.log(res);
            this.setFoundDocumentsInStore();
          }).catch(error => {
            this.setState({preloaderShow: false});
            console.log(error);
          });
        })
        .catch(error => {
          console.log(error);
          this.setState({preloaderShow: false});
        });

        this.setState({preloaderShow: true});
        API.get(
          '/document_g_bs?skuStr=' + sku
        ).then((res) => {
          this.setState({preloaderShow: false});
          if(_.isEmpty(res.data['hydra:member'])){
            console.log('No GB version');
          } else {
            console.log(res);
            this.setState({preloaderShow: true});
            API.delete(
              '/document_g_bs/' + _.head(res.data['hydra:member']).id
            ).then((res) => {
              this.setState({preloaderShow: false});
              console.log(res);
            }).catch((error) => {
              this.setState({preloaderShow: false});
              console.log(error);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({preloaderShow: false});
        });

        this.setState({preloaderShow: true});
        API.get(
          '/document_f_rs?skuStr=' + sku
        ).then((res) => {
          this.setState({preloaderShow: false});
          if(_.isEmpty(res.data['hydra:member'])){
            console.log('No FR version');
          } else {
            console.log(res);
            this.setState({preloaderShow: true});
            API.delete(
              '/document_f_rs/' + _.head(res.data['hydra:member']).id
            ).then((res) => {
              this.setState({preloaderShow: false});
              console.log(res);
            }).catch((error) => {
              console.log(error);
              this.setState({preloaderShow: false});
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({preloaderShow: false});
        });

        this.setState({preloaderShow: true});
        API.get(
          '/document_d_es?skuStr=' + sku
        ).then((res) => {
          this.setState({preloaderShow: false});
          if(_.isEmpty(res.data['hydra:member'])){
            console.log('No DE version');
          } else {
            console.log(res);
            this.setState({preloaderShow: true});
            API.delete(
              '/document_d_es/' + _.head(res.data['hydra:member']).id
            ).then((res) => {
              console.log(res);
              this.setState({preloaderShow: false});
            }).catch((error) => {
              console.log(error);
              this.setState({preloaderShow: false});
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({preloaderShow: false});
        });
      }
    });
  }

  createButtons(cell, row) {
    return(
      <div>
      <button onClick={() => this.editDocument(row)}>
        <i className="glyphicon glyphicon-edit"></i>
      </button>
      <button onClick={() => this.removeDoc(row)}>
        <i className="glyphicon glyphicon-remove"></i>
      </button>
      </div>
    );
  }

  editDocument(row){
    let sku = row.sku;
    if(!_.isEmpty(sku)){
      this.setState({preloaderShow: true});
      API.get(
        '/documents?skuStr=' + sku
      ).then(res => {
        this.setState({preloaderShow: false});
        this.props.setLanguages({
          gb: _.head(res.data['hydra:member']).gb,
          fr: _.head(res.data['hydra:member']).fr,
          de: _.head(res.data['hydra:member']).de,
        });
      }).catch(error => {
        console.log(error);
        this.setState({preloaderShow: false});
      });
    }
    this.props.setCurrentSku({ "className" : "sku", "value" : [ sku ], "type" : "one-field-without-ext", "iteration" : 0 });
    this.setState({lang: ''});
    let document = this.props.docs.filter(el => {
      return _.head(el.sku.value) === sku;
    });
    let preparedDocument = PrepareDocument(_.head(document));
    this.props.setSkuInput({ "className" : "sku", "value" : [ "" ], "type" : "one-field-without-ext", "iteration" : 0 });
    this.props.setProductTitleStr("");
    this.setState({preloaderShow: true});
    API.get('/documents?skuStr=' + sku)
      .then(res => {
        this.setState({preloaderShow: false});
        this.props.setLanguages({
          gb: _.head(res.data['hydra:member']).gb,
          fr: _.head(res.data['hydra:member']).fr,
          de: _.head(res.data['hydra:member']).de,
        });
        
        this.props.fillDocument(preparedDocument, _.head(res.data['hydra:member']).id);
      })
      .catch(error => {
        this.setState({preloaderShow: false});
        console.log(error);
      });
  }
  
  serializeParams = function(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  prepareUrl = queryParams => {
    let params = {...queryParams};
    if(_.isEmpty(params.skuStr)) {
      delete params.skuStr;
    }
    if(_.isEmpty(params.productTitleStr)) {
      delete params.productTitleStr;
    }
    if(!params.gb){
      delete params.gb;
    }
    if(!params.fr){
      delete params.fr;
    }
    if(!params.de){
      delete params.de;
    }

    return this.serializeParams(params);
  }

  getDocumentsList = (page = 1, sizePerPage = this.state.itemsPerPage, lang = this.state.lang) => {
    let params = {...this.state.queryParams};
    params.itemsPerPage = sizePerPage;
    params.page = page;

    if(!_.isEmpty(lang)){
      switch(lang){
        case 'GB':
          params.gb = true;
          break;
        case 'FR':
          params.fr = true;
          break;
        case 'DE':    
          params.de = true;
          break;
      }
    } 
    
    if(!_.isEmpty(_.head(this.props.skuInput.value))) {
      params.skuStr = _.head(this.props.skuInput.value);
    } else if(!_.isEmpty(this.props.productTitleStr)) {
      params.productTitleStr = this.props.productTitleStr;
    }
    let documentsPromise;

    return documentsPromise = new Promise((resolve, reject) => {
      this.setState({preloaderShow: true});
      
      API.get(
        '/documents?' + this.prepareUrl(params)
      ).then((res) => {
        this.setState({preloaderShow: false, totalSize: res.data['hydra:totalItems']});
        resolve(res);
      }).catch((error) => {
        this.setState({preloaderShow: false});
        reject(error);
      });
    });
  }

  displayPreloader() {
    if(this.state.preloaderShow){
      return(
        <div className='preloader-screen'>
          <Loader
            type="Oval"
            color="#00BFFF"
            height="100"
            width="100"
          />
        </div>
      );
    }
  }

  render() {
    let documents = [];
    if(this.state.usersLoaded) {
      let users = this.state.users;
      documents = _.map(this.props.docs, (doc, index) => {
        let translatedGB = doc.gb;
        let translatedFR = doc.fr;
        let translatedDE = doc.de;
        let stateNamesT = ['NOWY', 'UŻYWANY', 'REGENEROWANY', 'UŻYWANY-REGENEROWANY']
        let state = doc.productState.value;
        let stateIndex = _.last(state);
        let stateName = stateNamesT[stateIndex];
        let createUserEmail = '-';
        let updateUserEmail = '-';
        _.forEach(users, el => {
          if(el.IRI === doc.createUser) {
            createUserEmail = el.email;
          }
          if(el.IRI === doc.updateUser) {
            updateUserEmail = el.email;
          }
        });
        let createDate = new Date(doc.createDate);
        let updateDate = new Date(doc.updateDate);
        const formatDate = date => {
          if(date == 'Invalid Date') {
            return '-';
          }else{
            let day = date.getDate(),
                month = date.getMonth()+1,
                year = date.getFullYear(),
                time = `${date.getHours()}:${date.getMinutes()}`;
            if(day < 10) {
              day = '0' + day.toString();
            }
            if(month < 10) {
              month = '0' + month.toString();
            }
            
            return `${day}-${month}-${year} ${time}`;
          }
        }
        return {
          id: index + (this.state.page - 1) * this.state.itemsPerPage,
          sku: _.head(doc.sku.value),
          productFullName: _.head(doc.productFullName.value),
          productState: stateName,
          language_gb: translatedGB ? 'X' : '-',
          language_fr: translatedFR ? 'X' : '-',
          language_de: translatedDE ? 'X' : '-',
          create_date: formatDate(createDate),
          create_user: createUserEmail,
          update_date: formatDate(updateDate),
          update_user: updateUserEmail
        };
      });
    }

    const searchDocuments = (event = null) => {
      if(!event || event.key === 'Enter'){
        this.props.setCurrentSku({...this.props.skuInput});
        this.setFoundDocumentsInStore();
        this.setState({lang: ''});
      }
    }

    const handlePageChange = (page) => {
      this.getDocumentsList(page).then(res => {
        this.props.setFoundDocuments(res.data['hydra:member']);
        this.setState({
          page: page,
          totalSize: res.data['hydra:totalItems']
        });
      });
    }

    const onSizePerPageList = sizePerPage => {
      this.getDocumentsList(this.state.page, sizePerPage).then(res => {
        this.props.setFoundDocuments(res.data['hydra:member']);
        this.setState({
          page: 1,
          itemsPerPage: sizePerPage,
          totalSize: res.data['hydra:totalItems']
        });
      });
    }

    const handleLang = lang => {
      this.setState({lang: lang});
      this.getDocumentsList(this.state.page, this.state.itemsPerPage, lang).then(res => {
        this.props.setFoundDocuments(res.data['hydra:member']);
        this.setState({totalSize: res.data['hydra:totalItems'], page: 1});
      });
    }

    const options = {
      page: this.state.page,
      sizePerPage: this.state.itemsPerPage,
      onPageChange: handlePageChange,
      sizePerPageList: [ 10, 30, 50, 100 ],
      onSizePerPageList: onSizePerPageList
    }

    return (
      <div>
        {this.displayPreloader()}
        <div>
          <label>SKU: </label>
          <input className="find-by-sku" value={{...this.props.skuInput}.value[0]} disabled={(this.state.skuLocked)? "disabled" : ""} onChange={(e) => {
            if(!_.isEmpty(e.target.value)){
              this.setState({productFullNameStrLocked: true, skuLocked: false});
            }else{
              this.setState({productFullNameStrLocked: false});
            }
            this.props.setSkuInput({ "className" : "sku", "value" : [ e.target.value ], "type" : "one-field-without-ext", "iteration" : 0 });
          }} onKeyDown={e=>{searchDocuments(e);}}/>
          <label>Nazwa produktu: </label>
          <input className="find-by-name" value={this.props.productFullNameStr} disabled={(this.state.productFullNameStrLocked)? "disabled" : ""} onChange={(e) => {
            if(!_.isEmpty(e.target.value)){
              this.setState({skuLocked: true, productFullNameStrLocked: false});
            }else{
              this.setState({skuLocked: false});
            }
            this.props.setProductTitleStr(e.target.value);
          }} onKeyDown={e=>{searchDocuments(e);}}/>
          <button className="btn btn-success button-sku" id="btn-sku" 
            onClick={()=>{searchDocuments();}}
          >
            Szukaj
          </button>
          <button onClick={()=>{
            handleLang('GB')
          }}>
            <ReactCountryFlag code="GB" svg />
          </button>
          <button onClick={()=>{
            handleLang('FR')
          }}>
            <ReactCountryFlag code="FR" svg />
          </button>
          <button onClick={()=>{
            handleLang('DE')
          }}>
            <ReactCountryFlag code="DE" svg />
          </button>
        </div>
        <BootstrapTable 
          data={ documents } 
          options={ options }
          fetchInfo={ {dataTotalSize: this.state.totalSize} }
          remote
          pagination
          condensed
        >
          <TableHeaderColumn width="2%" dataField='id' isKey={ true }>ID</TableHeaderColumn>
          <TableHeaderColumn width="9%" dataField='sku'>SKU</TableHeaderColumn>
          <TableHeaderColumn width="24%" tdStyle={ { whiteSpace: 'unset', overflow: 'unset' } } dataField='productFullName'>Tytuł shoper</TableHeaderColumn>
          <TableHeaderColumn width="8%" dataField='productState'>Stan</TableHeaderColumn>
          <TableHeaderColumn width="10%" dataField='edit' dataFormat={ this.createButtons.bind(this) } ref='table'>Dodatkowa kolumna</TableHeaderColumn>
          <TableHeaderColumn width="3%" dataField='language_gb'><ReactCountryFlag code="GB" svg /></TableHeaderColumn>
          <TableHeaderColumn width="3%" dataField='language_fr'><ReactCountryFlag code="FR" svg /></TableHeaderColumn>
          <TableHeaderColumn width="3%" dataField='language_de'><ReactCountryFlag code="DE" svg /></TableHeaderColumn>
          <TableHeaderColumn width="8%" tdStyle={ { whiteSpace: 'unset', overflow: 'unset' } } dataField='create_date'>Utworzono</TableHeaderColumn>
          <TableHeaderColumn width="11%" dataField='create_user'>Autor</TableHeaderColumn>
          <TableHeaderColumn width="8%" tdStyle={ { whiteSpace: 'unset', overflow: 'unset' } } dataField='update_date'>Zaktualizowano</TableHeaderColumn>
          <TableHeaderColumn width="11%" dataField='update_user'>Aktualizował</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    skuInput: state.skuInput,
    productTitleStr: state.productTitleStr
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentSku: currentSku => dispatch({type: actionTypes.SET_CURRENT_SKU, currentSku: currentSku}),
    setCurrentName: currentName => dispatch({type: actionTypes.SET_CURRENT_NAME, currentName: currentName}),
    setFoundDocuments: foundDocuments => dispatch({type: actionTypes.SET_FOUND_DOCUMENTS, foundDocuments: foundDocuments}),
    setSkuInput: skuInput => dispatch({type: actionTypes.SET_SKU_INPUT, skuInput: skuInput}),
    setProductTitleStr: productTitleStr => dispatch({type: actionTypes.SET_PRODUCT_TITLE_STR, productTitleStr: productTitleStr}),
    setLanguages: languages => dispatch({type: actionTypes.SET_LANGUAGES, languages: languages}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextFilter);