import prepareCollectionForSubmit from './prepare_collection_for_submit';
import AlertMessages from '../data/alert_messages';
import _ from 'lodash';
import { resetState } from './initial_state';
import API from '../api';

const handleSubmit = (e, documentIdAPI, formFields, language, values) => {
  e.preventDefault();
  let collection = prepareCollectionForSubmit(formFields);

  let handleSubmitPromise = new Promise((resolve, reject) => {
    switch(language) {
      case 'pl':
        if(documentIdAPI){
          reject(AlertMessages.messages.documentAlreadyExists);
        }else{
          if(!collection.productFullName.value) {
            reject(AlertMessages.messages.fullNameIsRequired);
            break;
          } else if(!collection.sku.value) {
            reject(AlertMessages.messages.skuIsRequired);
            break;
          }
          API.get(
            '/documents?skuStr=' + _.head(collection.sku.value)
          ).then(res => {
            if(_.isEmpty(res.data['hydra:member'])) {
              collection = {
                ...collection, 
                createUser: window.localStorage.getItem('IRI'),
                updateUser: null,
                createDate: new Date(),
                updateDate: new Date()
              };
              API.post(
                '/documents',
                JSON.parse(JSON.stringify(collection))
              ).then(function (res) {
                console.log(res);
                let collectionSku = '';
                if(collection.sku.value !== undefined){
                  collectionSku = collection.sku.value[0];
                }
                resolve({ 
                  forState: {...resetState(values), tabKey: 2},
                  currentSku: {
                    "className" : "sku",
                    "value" : [ collectionSku ],
                    "type" : "one-field-without-ext",
                    "iteration" : 0
                  }
                });
              })
              .catch(function (error) {
                console.log(error);
                reject(error);
              });
            } else { 
              reject(AlertMessages.messages.skuExists);
            }
          }).catch(error => {
            console.log(error);
          });
        }
        break;
      case 'gb':
        if(documentIdAPI){
          let sku = _.head(collection.sku.value);
        
          API.get(
            '/documents?skuStr=' + sku
          ).then(function (res) {
            if(_.isEmpty(res.data['hydra:member'])){
              console.log('No document with sku: ' + sku);
            } else {
              console.log(res);
              API.put(
                '/documents/' + _.head(res.data['hydra:member']).id, 
                {gb: true}
              ).then(function (res) {
                console.log(res);
              })
              .catch(function (error) {
                console.log(error);
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });

          API.post(
            '/document_g_bs', 
            JSON.parse(JSON.stringify(collection))
          ).then(function (res) {
            console.log(res);
            resolve({ 
              forState: {...resetState(values), tabKey: 2}
            });
          })
          .catch(function (error) {
            console.log(error);
          });

        }else{
          reject(AlertMessages.messages.baseLanguageVersionNotExist);
        }
        break;
      case 'fr':
        if(documentIdAPI){
          let sku = _.head(collection.sku.value);
        
          API.get(
            '/documents?skuStr=' + sku
          ).then(function (res) {
            if(_.isEmpty(res.data['hydra:member'])){
              console.log('No document with sku: ' + sku);
            } else {
              console.log(res);
              API.put(
                '/documents/' + _.head(res.data['hydra:member']).id, 
                {fr: true}
              ).then(function (res) {
                console.log(res);
              })
              .catch(function (error) {
                console.log(error);
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });

          API.post(
            '/document_f_rs', 
            JSON.parse(JSON.stringify(collection))
          ).then(function (res) {
            console.log(res);
            resolve({ 
              forState: {...resetState(values), tabKey: 2}
            });
          })
          .catch(function (error) {
            console.log(error);
          });

        }else{
          reject(AlertMessages.messages.baseLanguageVersionNotExist);
        }
        break;
      case 'de':
        if(documentIdAPI){
          let sku = _.head(collection.sku.value);
        
          API.get(
            '/documents?skuStr=' + sku
          ).then(function (res) {
            if(_.isEmpty(res.data['hydra:member'])){
              console.log('No document with sku: ' + sku);
            } else {
              console.log(res);
              API.put(
                '/documents/' + _.head(res.data['hydra:member']).id, 
                {de: true}
              ).then(function (res) {
                console.log(res);
              })
              .catch(function (error) {
                console.log(error);
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });

          API.post(
            '/document_d_es', 
            JSON.parse(JSON.stringify(collection))
          ).then(function (res) {
            console.log(res);
            resolve({ 
              forState: {...resetState(values), tabKey: 2}
            });
          })
          .catch(function (error) {
            console.log(error);
          });

        }else{
          reject(AlertMessages.messages.baseLanguageVersionNotExist);
        }
        break;
      default:
        reject(AlertMessages.messages.languageNotFound);
    }
  });
  return handleSubmitPromise;
}

export default handleSubmit;