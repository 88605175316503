import React, { Component } from 'react';
import DocumentsComponent from './documents_component';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';

class DocumentsModule extends Component{

  fillDocument(doc, idAPI=undefined){
    this.props.fillDocument(doc, idAPI);
  }

  render(){
    return (
      <div>
        <div className='documents translated-documents'>
          <div className='jumbotron'>
            <DocumentsComponent
              fillDocument={this.fillDocument.bind(this)}
              docs={this.props.foundDocuments}
              resetState={this.props.resetState}
            />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    currentSku: state.currentSku,
    currentName: state.currentName,
    foundDocuments: state.foundDocuments,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setFoundDocuments: foundDocuments => dispatch({type: actionTypes.SET_FOUND_DOCUMENTS, foundDocuments: foundDocuments}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsModule);
