import React from "react";

export default {
  modules: [
    {
      type: "one-field-without-ext",
      class: "product-full-name",
      name: "Tytuł shoper",
    },
    {
      type: "one-field-without-ext",
      class: "sku",
      name: "Numer magazynowy",
    },
    {
      type: "two-fields",
      class: "product-title",
      name: "Nazwa przedmiotu",
      secondClass: "product-desc",
      secondName: "Opis",
    },
    {
      type: "radio-field",
      class: "product-state",
      title: "stan",
      columns: "3",
      groupName: "product-state",
      options: ["NOWY", "UŻYWANY", "REGENEROWANY", "UŻYWANY-REGENEROWANY"],
      label: "STAN CZĘŚCI",
    },
    {
      type: "one-field",
      class: "about-numbers",
      name: "O numerach",
      label: "O NUMERACH",
    },
    {
      type: "one-field",
      class: "about-spare-numbers",
      name: "O numerach zamiennych",
      label: "O NUMERACH ZAMIENNYCH",
    },
    {
      type: "one-field",
      class: "about-service-numbers",
      name: "O numerach serwisowych",
      label: "O NUMERACH SERWISOWYCH",
    },
    {
      type: "two-fields-extended",
      class: "additional-parameter-name",
      name: "Dodatkowy parametr",
      secondClass: "additional-parameter-desc",
      secondName: "Opis",
    },
    {
      type: "one-field",
      class: "additional-single-parameter",
      name: "Pojedynczy dodatkowy parametr",
      label: "Pojedynczy dodatkowy parametr",
    },
    {
      type: "cars-collection",
      class: "cars-collection",
      bodyOptions: [
        "CC Cabrio",
        "Coupe",
        "Crossover",
        "Furgon",
        "Grandtour",
        "GT",
        "Hatchback",
        "Kabriolet",
        "Kombi",
        "Minivan",
        "Pick-up",
        "RS Sport",
        "Sedan",
        "Sport",
        "SW",
        "SUV",
        "Van",
        "MCV",
        "MCV Stepway",
        "Liftback",
      ],
      label: "ZASTOSOWANIE",
    },
  ],
};
