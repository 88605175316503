import axios from 'axios';
import _ from 'lodash';

const getHeaders = () => {
  let headers = {headers: { 
    Accept: "application/ld+json"
  }};
  if(!_.isEmpty(window.localStorage.getItem("JWT"))){
    let JWT = window.localStorage.getItem("JWT");
    headers.headers = {...headers.headers, Authorization: "Bearer " + JWT}
  } else {
    delete headers.headers.Authorization;
  }
  return headers;
}

export default axios.create({
  baseURL: `https://fagen.jserv.pl/new`,
  // baseURL: `http://localhost:8080`,
  //baseURL: `http://localhost/fagen-api/public/`,
  ...getHeaders()
});