import _ from 'lodash';

const prepareHtml = (collection, language) => {
  var html = prepareCollection(collection, language);
  return html;
}

const prepareCollection = (collection, language) => {

  function decorator(before, arr, end, beforeAll=undefined){
    if(!_.isEmpty(_.head(arr))){
      let newStr = _.join(_.map(arr, el => {
        return before + el + end;
      }), '');
      if(beforeAll){
        return beforeAll + newStr
      }else{
        return newStr;
      }
    }
    return '';
  }

  function findInCollection(className){
    let filteredElements = _.filter(collection, el => {
      return el.className === className;
    });
    return _.map(filteredElements, n => {
      if(_.isArray(n.value)){
        return _.head(n.value)
      }else{
        return n.value;
      }
    });
  }

  function findAdditionalParameters(){
    let filteredNames = _.filter(collection, el => {
      return el.className === 'additional-parameter-name';
    });
    let filteredDescriptions = _.filter(collection, el => {
      return el.className === 'additional-parameter-desc';
    });

    let newArr = _.map(filteredNames, (el, index) => {
      let descriptions = _.join(_.compact(_.map(filteredDescriptions, n => {
        if(n.twoFieldsExtendedIteration === index+1){
          return `<p>${_.head(n.value)}</p>`;
        }
      })), '');
      return `<h2 class="additional-parameter">${el.value || _.head(el.value)}</h2>${descriptions}`
    });

    return _.join(newArr, '');
  }

  function prepareCarsCollection(){
    let prepareModel = (model) => {
      let newModel = model;
      if(language !== 'pl') newModel = _.replace(model, 'r.', '');
      return newModel;
    };
    let foundEl = _.find(collection, el => {
      return el.className === 'cars-collection';
    });
    if(foundEl){
      let carsArray = [];
      _.forEach(foundEl.value, n => {
        let foundElInCarsArrayIndex = _.findIndex(carsArray, m => {
          return m.make === n.make;
        });
        if(foundElInCarsArrayIndex > -1){
          carsArray[foundElInCarsArrayIndex].rest.push({model: n.model, engines: n.engines, bodies: n.bodies});
        }else{
          carsArray.push({make: n.make, rest: [{model: n.model, engines: n.engines, bodies: n.bodies}]});
        }
      });
      return _.join(_.map(carsArray, (car, index) => {
        let lines = _.map(car.rest, el => {
          return _.join(_.map(el.model, n => {
            return `<p>${prepareModel(n.value)} ${_.join(_.map(el.engines, engine => engine.value), ' ')} ${_.join(_.map(el.bodies, body => body.value), ' ')}</p>`;
          }), '');
        });
        let return_string = `<b>${car.make}</b>${_.join(lines, '')}`;
        return index > 0 ? `</br>${return_string}` : return_string;
      }), '');
    }
    return '';
  }

  let style = '',
      productStateT = 'STAN CZĘŚCI',
      productStatePrefix = '<ul><li>',
      productStateSuffix = '</ul></li>',
      aboutNumbersT = 'O NUMERACH',
      aboutSpareNumbersT = 'O NUMERACH ZAMIENNYCH',
      aboutServiceNumbersT = 'O NUMERACH SERWISOWYCH',
      stateNamesT = ['NOWY', 'UŻYWANY', 'REGENEROWANY', 'UŻYWANY-REGENEROWANY'],
      applicationT = 'ZASTOSOWANIE';

  let htmlStyle = '<style>.szer table td > div > div{font-size: 16px;} h1{font-size: 1.4em;} h2{font-size: 1.1em;}</style>';

  switch(language){
    case 'pl':
      style = '';
      productStateT = 'STAN CZĘŚCI';
      productStatePrefix = '<ul><li>';
      productStateSuffix = '</ul></li>';
      aboutNumbersT = 'O NUMERACH';
      aboutSpareNumbersT = 'O NUMERACH ZAMIENNYCH';
      aboutServiceNumbersT = 'O NUMERACH SERWISOWYCH';
      stateNamesT = ['NOWY', 'UŻYWANY', 'REGENEROWANY', 'UŻYWANY-REGENEROWANY'];
      applicationT = 'ZASTOSOWANIE';
      break;
    case 'gb':
      style = htmlStyle;
      productStateT = 'Condition';
      productStatePrefix = '';
      productStateSuffix = '';
      aboutNumbersT = 'Part Number';
      aboutSpareNumbersT = 'OE/OEM Reference Numbers';
      aboutServiceNumbersT = 'Other OE/OEM Replacement Reference Numbers';
      stateNamesT = ['New', 'Used', 'Remanufactured', 'Remanufactured'];
      applicationT = 'Used for:';
      break;
    case 'fr':
      style = htmlStyle;
      productStateT = 'État';
      productStatePrefix = '';
      productStateSuffix = '';
      aboutNumbersT = 'Numéro de Pièce';
      aboutSpareNumbersT = 'OE/OEM Référence';
      aboutServiceNumbersT = 'Autre OE/OEM Référence';
      stateNamesT = ['Neuf', 'Occasion', 'Réusiné', "Réusiné D'occasion"];
      applicationT = 'Affectations:';
      break;
    case 'de':
      style = htmlStyle;
      productStateT = 'Zustand';
      productStatePrefix = '';
      productStateSuffix = '';
      aboutNumbersT = 'Teilenummern';
      aboutSpareNumbersT = 'OE/OEM Referenznummern';
      aboutServiceNumbersT = 'Andere OE/OEM Referenznummern';
      stateNamesT = ['Neu', 'Gebraucht', 'Generalüberholt', 'Gebraucht Generaltüberholt'];
      applicationT = 'Verwendung für:';
      break;
  }

  function getStateNameForProductState(value){
    let index = _.last(_.toArray(_.head(value)));
    return [stateNamesT[index]]
  }

  let productTitle = findInCollection('product-title');
  let productDesc = findInCollection('product-desc');
  let productState = getStateNameForProductState(findInCollection('product-state'));
  let aboutNumbers = findInCollection('about-numbers');
  let aboutSpareNumbers = findInCollection('about-spare-numbers');
  let aboutServiceNumbers = findInCollection('about-service-numbers');
  let additionalParameters = findAdditionalParameters();
  let additionalSingleParameter = findInCollection('additional-single-parameter');
  let carsCollection = prepareCarsCollection();
  if(carsCollection){
    carsCollection = `<h2 id="product-application">${applicationT}</h2>` + carsCollection;
  }

  productTitle = decorator(
    '<h2 id="product-title" style="padding-bottom: 5px; font-size: 24px;">',
    productTitle,
    "</h2>"
  );
  productDesc = decorator('<p class="product-desc">', productDesc, '</p>');
  productState = decorator(`${productStatePrefix}<b id=${productState}>${productStateT}: `, productState , `</b>${productStateSuffix}`);
  aboutNumbers = decorator('<p>', aboutNumbers, '</p>', `<h2 id="about-numbers">${aboutNumbersT}: </h2>`);
  aboutSpareNumbers = decorator('<p>', aboutSpareNumbers, '</p>', `<h2 id="about-spare-numbers">${aboutSpareNumbersT}: </h2>`);
  aboutServiceNumbers = decorator('<p>', aboutServiceNumbers, '</p>', `<h2 id="about-service-numbers">${aboutServiceNumbersT}: </h2>`);
  additionalSingleParameter = decorator('<p>', additionalSingleParameter, '</p>');

  let html = style + productTitle + productDesc + productState + aboutNumbers + aboutSpareNumbers + aboutServiceNumbers + additionalParameters + additionalSingleParameter + carsCollection;

  return html;
}

export default prepareHtml;
