import React, {Component} from 'react';
import RadioComponent from './radio_component';
import _ from 'lodash';

class RadioFieldModuleConst extends Component {
  constructor(props){
    super(props);
    this.state = {chosenRadio: ''}
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.buttonClicked){
      this.setVal({className: this.props.className, value: _.head(nextProps.values), type: this.props.fieldType, iteration: 0, label: '' });
      this.setState({
        chosenRadio: _.head(nextProps.values)
      });
    }
  }

  setVal(value){
    this.props.onUpdateVal(value);
    this.setState({chosenRadio: value.value});
  }

  render(){
    const RadioButtons = () => {
      const optionsWrapped = this.props.options.map((button, index)=>{
        return <RadioComponent chosenRadio={this.state.chosenRadio} onUpdate={this.setVal.bind(this)} key={`${this.props.radioTitle}-${index}`} className={this.props.className} radioColumns={this.props.bootstrapColPerElement} radioPositionName={`${this.props.radioGroupName}-${index}`} radioTitle={button} label={this.props.label} radioGroupName={this.props.radioGroupName} fieldType={this.props.fieldType} />;
      });
      return optionsWrapped;
    };
    return (
      <div className='jumbotron'>
        <div className='row'>
          <label>{this.props.radioTitle}</label>
          <RadioButtons />
        </div>
      </div>
    );
  }
};

export default RadioFieldModuleConst;
