import React, {Component} from 'react';
import {SimpleSelect, MultiSelect} from 'react-selectize';
import AlertContainer from 'react-alert';
import AlertMessages from '../data/alert_messages';
import _ from 'lodash';
import API from '../api';
import Loader from 'react-loader-spinner';

const initialState = {
  make: undefined, model: [], engines_checked: false, engines: [],
  filtered_engines: [], bodies_checked: false, bodies: [], cars_list: [],
  properties_to_omit: []
}

class CarsCollectionModule extends Component {

  constructor(props){
    super(props);
    this.state = {...initialState, cars: []};
  }

  componentDidMount() {
    this.getCarsList().then(res => {
      this.setState({cars: res.data['hydra:member']});
    });
  }

  getCarsList = () => {
    let carsPromise;
    this.setState({preloaderShow: true});
    return carsPromise = new Promise((resolve, reject) => {
      API.get(
        '/cars?pagination=false'
      ).then((res) => {
        this.setState({preloaderShow: false});
        resolve(res);
      })
      .catch((error) => {
        this.setState({preloaderShow: false});
        reject(error);
      });
    });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.buttonClicked){
      this.setVal({className: this.props.className, fieldIndex: 0, label: this.props.label, type: this.props.fieldType, value: nextProps.values});
      this.setState({
        cars_list: nextProps.values,
        make: undefined,
        model: [],
        engines_checked: false,
        engines: [],
        filtered_engines: [],
        bodies_checked: false,
        bodies: [],
        propertiesToOmit: []
      });
    }
  }

  setVal(value){
    this.props.onUpdateVal(value);
  }

  resetStateWithOmitSomeProperties(){
    let propertiesToOmit = [...this.state.properties_to_omit];
    if(!_.isEmpty(propertiesToOmit)){
      return this.setState(_.omit(initialState, propertiesToOmit));
    }
  }

  setPropertiesToOmit(arr){
    this.setState({properties_to_omit: arr});
  }

  returnsFilteredEnginesByMakeAndModel(model){
    let filteredCars = this.state.cars.filter(car => {
      return car.make === this.state.make && _.includes(model.map(e=>e.label), car.model);
    });
    return _.intersection(...filteredCars.map(e=>e.engine));
  }

  updateMake(make){
    this.setPropertiesToOmit(['make', 'cars_list']);
    this.resetStateWithOmitSomeProperties();
    this.setState({
      make: !!make ? make.value : make
    });
  }

  updateModel(model){
    this.setPropertiesToOmit(['make', 'model', 'cars_list']);
    this.resetStateWithOmitSomeProperties();
    this.setState({
      model: model,
      filtered_engines: this.returnsFilteredEnginesByMakeAndModel(model)
    });
  }

  resetStates(){
    this.setState({
      make: undefined,
      model: [],
      engines_checked: false,
      engines: [],
      filtered_engines: [],
      bodies_checked: false,
      bodies: [],
      propertiesToOmit: []
    });
  }

  updateCarsList(){
    var state = this.state,
        self = this,
        {make, model, engines_checked, engines, bodies_checked, bodies, cars_list} = state;

    model = _.sortBy(model, 'label');
    engines = _.sortBy(engines, 'label');
    bodies = _.sortBy(bodies, 'label');

    var newCar = {make, model, engines: sortByLabel(engines), bodies: sortByLabel(bodies)},
        filteredCars = cars_list.filter(el=>el.make===make);

    function sortByLabel(el){
      return _.sortBy(el, 'label');
    }

    function addNewCarToList(){
      let newCarsList = _.sortBy([...cars_list, newCar], 'make');
      self.setVal({className: self.props.className, value: newCarsList, type: self.props.fieldType, fieldIndex: 0, label: self.props.label});
      self.resetStates();
      self.setState({
        cars_list: newCarsList
      });
    }

    function throwError(msg){
      self.msg.error(msg);
    }

    if(make && model){
      if(cars_list.length > 0){
        if(filteredCars.length){
          let filteredCar = _.head(filteredCars);

          let modelsDiff = _.difference(model.map(el=>el.value), filteredCar.model.map(el=>el.value)),
              enginesDiff = _.difference(engines.map(el=>el.value), filteredCar.engines.map(el=>el.value)),
              bodiesDiff = _.difference(bodies.map(el=>el.value), filteredCar.bodies.map(el=>el.value));

          if(_.isEmpty([...modelsDiff, ...enginesDiff, ...bodiesDiff])){
            throwError(AlertMessages.messages.carAlreadyExist);
          }else{
            if(modelsDiff.length === model.length){
              addNewCarToList();
            }else{
              throwError(AlertMessages.messages.carAlreadyExist);
            }
          }
        }else{
          addNewCarToList();
        }
      }else{
        addNewCarToList();
      }
    }else{
      throwError(AlertMessages.messages.fillMakeAndModel);
    }

  }

  displayPreloader() {
    if(this.state.preloaderShow){
      return(
        <div className='preloader-screen'>
            <Loader
              type="Oval"
              color="#00BFFF"
              height="100"
              width="100"
            />
        </div>
      );
    }
  }

  alertOptions = {offset: 14,position: 'bottom left',theme: 'dark',time: 5000,transition: 'scale'}

  render(){
    var self = this,
        cars = this.state.cars,
        bodyOptions = this.props.bodyOptions;
    var { make, model, engines_checked, engines, filtered_engines,
          bodies_checked, bodies, cars_list } = this.state;

    function makeStringFromArrayObjectsValues(arr){
      return _.isEmpty(arr) ? '' : _.join(arr.map(el=>el.value), ' ');
    }

    function labelIt(it){
      return !!it ? {label: it, value: it} : undefined;
    }

    function returnsSortedUniqMakes(){
      let uniqCarsByMakeLabeled = _.uniqBy(cars, 'make').map(el=>labelIt(el.make));
      return _.sortBy(uniqCarsByMakeLabeled, 'label');
    }

    function returnsArrayOfSortedAndFilteredModelsByMake(){
      let filteredCars = cars.filter(car => car.make === self.state.make);
      let arrayOfFilteredAndLabeledModels = filteredCars.map(el => labelIt(el.model));
      return _.sortBy(arrayOfFilteredAndLabeledModels, 'label');
    }

    function returnsSortedAndFilteredEnginesFromState(){
      return _.sortBy(filtered_engines.map(engine => {return {label: engine, value: engine}}), 'label');
    }

    function returnsSortedBodyOptions(){
      let labeledBodyOptions = bodyOptions.map(v => labelIt(v));
      return _.sortBy(labeledBodyOptions, 'label');
    }

    return (
      <div className='jumbotron cars-container'>
        {this.displayPreloader()}
        <div className='row'>

          <SimpleSelect
            placeholder='Wybierz markę'
            options={returnsSortedUniqMakes()}
            value={labelIt(make)}
            onValueChange={make => this.updateMake(make)}
          />

          <MultiSelect
            placeholder='Wybierz modele'
            options={returnsArrayOfSortedAndFilteredModelsByMake()}
            disabled={_.isEmpty(make)}
            values={model}
            onValuesChange={model => this.updateModel(model)}
          />

          <input
            type='checkbox'
            name='enable_engines'
            disabled={_.isEmpty(model)}
            checked={engines_checked}
            onChange={() => {
              self.setState({engines_checked: !engines_checked, engines: []})
            }}
          />
          <label style={{opacity: !!model ? 1 : 0.4}}>Wersje silnikowe</label>

          <MultiSelect
            values={engines}
            options={returnsSortedAndFilteredEnginesFromState()}
            placeholder = "Wybierz wersje silnikowe"
            disabled={!engines_checked}
            onValuesChange={(val)=>{
              self.setState({engines: val});
            }}
          />

          <input
            type='checkbox'
            name='enable_car_bodies'
            disabled={_.isEmpty(model)}
            checked={bodies_checked}
            onChange={() => {
              self.setState({bodies_checked: !bodies_checked, bodies: []})
            }}
          />
          <label style={{opacity: !!model ? 1 : 0.4}}>Typ nadwozia</label>

          <MultiSelect
            values={bodies}
            options={returnsSortedBodyOptions()}
            placeholder = "Wybierz typy nadwozia"
            disabled={!bodies_checked}
            onValuesChange={(val)=>{
              self.setState({bodies: val});
            }}
          />

          <button
            type="button"
            onClick={() => {
              this.updateCarsList();
            }}
            className={`btn btn-success btn-add-car-to-the-list`}
          >
          <i className="glyphicon glyphicon-plus"></i>
          </button>

          <div className='list-group'>
            {cars_list.map((key) => {
              return (
                <a key={_.join(_.map(key.model, el => el.value.replace(/\s/g,'').replace(/\./g,'')), '-')} className="list-group-item clearfix">
                  <span className="car-row-text">{`${key.make} ${makeStringFromArrayObjectsValues(key.model)} ${makeStringFromArrayObjectsValues(key.engines)} ${makeStringFromArrayObjectsValues(key.bodies)}`}</span>
                  <span className="pull-right">
                      <span
                        className="btn btn-xs btn-danger"
                        onClick={() => {
                            let newCarsList = _.pull(cars_list, key);
                            this.setState({cars_list: newCarsList});
                            self.setVal({
                              className: self.props.className, value: newCarsList,
                              type: self.props.fieldType, fieldIndex: 0, label: self.props.label});
                        }}
                      >
                        <span className="glyphicon glyphicon-remove" aria-hidden="true"></span>
                      </span>
                  </span>
                </a>
              )
            })}
          </div>

          <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
        </div>
      </div>
    );
  }
};

export default CarsCollectionModule;
