import React, {Component} from 'react';
import TwoFieldsModule from './two_fields_module';
import AlertContainer from 'react-alert';
import AlertMessages from '../data/alert_messages';
import _ from 'lodash';

class TwoFieldsExtendedModule extends Component {

  constructor(props){
    super(props);
    this.state = { twoFieldsModules: [], titleEmpty: true };
    this.addField = this.addField.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.buttonClicked){
      _.times(nextProps.values.length, n => {
        this.onUpdateVal(nextProps.buttonClicked, {twoFieldsExtendedIteration: n+1, className: this.props.className, iteration: 0, label: '', type: this.props.fieldType, value: nextProps.values[n].value});
        _.times(nextProps.values[n].secondValues.length, m => {
          this.onUpdateVal(nextProps.buttonClicked, {twoFieldsExtendedIteration: n+1, className: this.props.secondClassName, iteration: m, label: undefined, type: this.props.fieldType, value: [nextProps.values[n].secondValues[m]]});
        });
      })
      this.setState({twoFieldsModules: nextProps.values});
    }
  }

  beforeUpdateVal(value){
    this.onUpdateVal(this.props.buttonClicked, value);
  }

  onUpdateVal(clicked, value){
    var newVal = value;
    if(value.className === this.props.className){
      this.setState({titleEmpty: _.isEmpty(value.value)});
    }
    if(!clicked){
      newVal.twoFieldsExtendedIteration = newVal.twoFieldsExtendedIndex + 1;
    }
    this.props.onUpdateVal(newVal);
  }

  onDeleteValue(className, type, extendedIteration){
    if(className === this.props.secondClassName){
      this.props.onDeleteValue(className, type, extendedIteration, false);
    }else{
      this.props.onDeleteValue(className, type, extendedIteration, true);
    }
  }

  alertOptions = {
    offset: 14,
    position: 'bottom left',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  addField() {
    const modules = [...this.state.twoFieldsModules];
    const iterator = modules.length;
    const titleEmpty = this.state.titleEmpty;
    if(iterator > 0){
      if(titleEmpty){
        this.msg.error(AlertMessages.messages.beforeInputEmpty);
        return;
      }
      this.setState({titleEmpty: true});
    }
    modules.push({value: [''], secondValues: ['']});
    this.setState({ twoFieldsModules: modules });
  }

  removeField(){
    const inputs = [...this.state.twoFieldsModules];
    const iterator = inputs.length;
    if(iterator > 0){
      inputs.pop();
      this.onDeleteValue(this.props.className, this.props.fieldType, iterator-1);
      this.setState({ twoFieldsModules: inputs });
    }else{
      this.msg.error(AlertMessages.messages.cantRemoveBaseField);
      return;
    }
  }

  render(){
    let prepareTwoFieldsModules = () => {
      return _.map([...this.state.twoFieldsModules], (el, index) => {
        return <TwoFieldsModule values={el.value} secondValues={el.secondValues} buttonClicked={this.props.buttonClicked} onDeleteValue={this.onDeleteValue.bind(this)} onUpdateVal={this.beforeUpdateVal.bind(this)} key={`${this.props.className}-${index}`} className={this.props.className} labelName={this.props.labelName} secondClassName={`${this.props.secondClassName}`} secondLabelName={this.props.secondLabelName} fieldType={this.props.fieldType} fieldIndex={index} label={this.props.label} />;
      });
    }
    let button = null;
    if([...this.state.twoFieldsModules].length > 0){
      button = <button type="button" onClick={this.removeField.bind(this)} className={`btn btn-danger btn-two-fields-ext btn-remove-${this.props.labelName}`}><i className="glyphicon glyphicon-minus"></i></button>
    }
    return (
      <div className='extended-container'>
        <div>{prepareTwoFieldsModules()}</div>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
        {button}
        <button type="button" onClick={this.addField} className={`btn btn-success btn-extended btn-add-${this.props.className}`}>
          {this.props.labelName}
        </button>
      </div>
    );
  }

};

export default TwoFieldsExtendedModule;
