// ok

import _ from 'lodash';
import PrepareHtml from './prepare_html';

const onDeleteValue = (className, type, extendedIteration, removeAll, stateObjArr, language) => {
  if(extendedIteration > -1){
    if(removeAll){
      stateObjArr = stateObjArr.filter(el => {
        if(el.type === type && el.twoFieldsExtendedIteration === extendedIteration+1){
          return false;
        }
        return true;
      });
    }else{
      let index = _.findLastIndex(stateObjArr, el => {
        return el.type === type && el.twoFieldsExtendedIteration === extendedIteration+1;
      });
      _.pullAt(stateObjArr, index);
    }
  }else{
    let index = _.findLastIndex(stateObjArr, el => {
      return el.className === className && el.type === type;
    })
    _.pullAt(stateObjArr, index);
  }
  console.log({form_fields: stateObjArr, html: PrepareHtml(stateObjArr, language)});
  
  return {form_fields: stateObjArr, html: PrepareHtml(stateObjArr, language)};
}

export default onDeleteValue;