import './App.css';
import React, { Component } from 'react';

import Fagen from './containers/fagen/Fagen';
import Auth from './containers/auth/Auth';
import checkToken from './helpers/check_token';

class App extends Component{

  authChecker = () => {
    const auth = (<div><Auth /></div>),
          fagen = (<div><Fagen /></div>);

    return checkToken() ? fagen : auth;
  }

  render(){
    return (
      <div>
        {this.authChecker()}
      </div>
    );
  };
  
};

export default App;