// ok

const updateObjectValuesByClassAndType = (type, className, values, stateValues) => {
  stateValues = stateValues.map(el => {
    if(el.type === type && el.className === className){
      el.values = values;
    }
    return el;
  });
  return {values: stateValues, buttonClicked: true};
}

export default updateObjectValuesByClassAndType;