import React, { Component } from "react";
import API from '../api';
import AlertContainer from 'react-alert';
import AlertMessages from '../data/alert_messages';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';

class NewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isAdmin: false
    };
  }

  handleCreateUser = (event) => {
    event.preventDefault();

    if (this.refs.registerForm.reportValidity()) {
      let submitData = {
        "email": this.state.email,
        "password": this.state.password
      }
  
      if(this.state.isAdmin) {
        submitData = {
          "email": this.state.email,
          "roles": ['ROLE_ADMIN'],
          "password": this.state.password
        }
      }
      
      this.setState({preloaderShow: true});
      API.post(
        '/register', submitData
      ).then(res => {
        console.log(res);
        
        this.props.setUsers([...this.props.users, {
          id: res.data.ID,
          email: this.state.email,
          admin: this.state.isAdmin
        }]);
        this.setState({email: '', password: '', isAdmin: false, preloaderShow: false});
        this.msg.success(AlertMessages.messages.userCreated);
      })
      .catch(error => {
        console.log(error);
        this.setState({preloaderShow: false});
        this.msg.error(AlertMessages.messages.userExists);
      });
    }
  }

  displayPreloader() {
    if(this.state.preloaderShow){
      return(
        <div className='preloader-screen'>
            <Loader
              type="Oval"
              color="#00BFFF"
              height="100"
              width="100"
            />
        </div>
      );
    }
  }

  render() {
    return (
      <div className='jumbotron new-user'>
        {this.displayPreloader()}
        <form onSubmit={this.handleCreateUser.bind(this)} ref="registerForm">
          <label>Email</label>
          <input required type="email" value={this.state.email} onChange={(e) => {
            this.setState({email: e.target.value})
          }}/>
          <label>Hasło</label>
          <input required type="password" value={this.state.password} onChange={(e) => {
            this.setState({password: e.target.value})
          }}/>
          <label>Uprawnienia administratora</label>
          <input
            name="isAdmin"
            type="checkbox"
            checked={this.state.isAdmin}
            onChange={(e) => {
              this.setState({isAdmin: !this.state.isAdmin})
            }} />
          <button
            type="submit"
            className={`btn btn-success btn-create-user`}
          >
          Dodaj
          </button>
        </form>
        <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setUsers: users => dispatch({type: actionTypes.SET_USERS, users: users}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewUser);