import React, {Component} from 'react';
import _ from 'lodash';

class InputConst extends Component{
  constructor(props){
    super(props);
    this.state = { inputValue: this.props.value, disabled: false };
  }

  componentWillMount(){
    this.setState({bold: !!this.props.bold});
  }

  componentWillReceiveProps(nextProps){
    this.setState({disabled: nextProps.disabled});
    if(nextProps.buttonClicked){
      this.setState({inputValue: nextProps.value});
    }
  }

  addBold(){
    if(!_.head(this.state.inputValue).match(/<(\/)?b[^>]*>/g)){
      this.props.onUpdate({className: this.props.className, value: [`<b>${this.state.inputValue}</b>`], type: this.props.fieldType, iteration: this.props.fieldIndex, label: this.props.label, twoFieldsExtendedIndex: this.props.twoFieldsExtendedIndex});
      this.setState({inputValue: [`<b>${this.state.inputValue}</b>`]})
    }else{
      let newInputVal = _.head(this.state.inputValue).replace(/<(\/)?b[^>]*>/g, '');
      this.props.onUpdate({className: this.props.className, value: [newInputVal], type: this.props.fieldType, iteration: this.props.fieldIndex, label: this.props.label, twoFieldsExtendedIndex: this.props.twoFieldsExtendedIndex});
      this.setState({inputValue: [newInputVal]});
    }
  }

  render(){
    return (
      <div>
        {this.state.bold && <button type='button' className='btn-bold' onClick={() => this.addBold()}>Pogrubienie</button>}
        <input disabled={this.state.disabled} value={this.state.inputValue} className={this.props.className} onChange={(e) => {
          this.props.onUpdate({className: this.props.className, value: [e.target.value], type: this.props.fieldType, iteration: this.props.fieldIndex, label: this.props.label, twoFieldsExtendedIndex: this.props.twoFieldsExtendedIndex});
          this.setState({inputValue: [e.target.value]})
        }}/>
      </div>
    );
  }
};

export default InputConst;
