import _ from 'lodash';
import jwt from 'jsonwebtoken';

const checkToken = (roleAdminNeeded = false) => {
  var tokenValid = false;

  if(!_.isEmpty(window.localStorage.getItem("JWT"))){
    const token = window.localStorage.getItem('JWT');
    var decodedToken=jwt.decode(token, {complete: true});
    var dateNow = new Date();
  
    if(decodedToken.payload.exp < dateNow.getTime()/1000|0){
      window.localStorage.removeItem("JWT");
    }else{
      if(roleAdminNeeded) {
        tokenValid = _.includes(decodedToken.payload.roles, 'ROLE_ADMIN');
      }else{
        tokenValid = true;
      }
    }
  }
  
  return tokenValid;
}


export default checkToken;