import FieldModules from '../data/field_modules';
import _ from 'lodash';

const prepareDataForSend = collection => {
  var mergedCollection = mergeCollection(collection);
  return mergedCollection;
}

const mergeCollection = collection => {
  var mergedCollection = [];

  function prepareOneFieldWithoutExt(obj){
    var index = returnsIndexByClass(obj.className);
    if(index < 0){
      mergedCollection.push(obj);
    }else{
      mergedCollection[index].value = _.head(obj.value);
    }
  }

  function prepareTwoFieldsExtended(obj){
    var indexExtended = returnsIndexByClassAndIteration(obj.className, obj.twoFieldsExtendedIteration);
    if(indexExtended < 0){
      mergedCollection.push(obj);
    }else{
      mergedCollection[indexExtended].value[obj.iteration] = _.head(obj.value);
    }
  }

  function prepareSimpleField(obj){
    var index = returnsIndexByClass(obj.className);
    if(index < 0){
      mergedCollection.push(obj);
    }else{
      mergedCollection[index].value[obj.iteration] = _.head(obj.value);
    }
  }

  function returnsIndexByClass(className){
    return _.indexOf(mergedCollection, _.head(mergedCollection.filter(o=>o.className===className)));
  }

  function returnsIndexByClassAndIteration(className, iteration){
    return _.indexOf(mergedCollection, _.head(mergedCollection.filter(o=>{
      return o.className===className && o.twoFieldsExtendedIteration === iteration;
    })));
  }

  _.forEach(collection, el => {
    if(_.includes(['one-field', 'two-fields', 'radio-field'], el.type)){
      prepareSimpleField(el);
    }else if(el.type === 'one-field-without-ext'){
      prepareOneFieldWithoutExt(el);
    }else if(el.type === 'two-fields-extended'){
      prepareTwoFieldsExtended(el);
    }else if(el.type === 'cars-collection'){
      prepareSimpleField(el);
    }
  });
  return mergedCollection;
}

export default prepareDataForSend;
