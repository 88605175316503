import prepareCollectionForSubmit from './prepare_collection_for_submit';
import { resetState } from './initial_state';
import API from '../api';

const handleUpdateSubmit = (form_fields, docIdAPI, language, values, languages) => {
  
  let collection = prepareCollectionForSubmit(form_fields, languages);
  let idAPI = docIdAPI;

  let handleUpdatePromise = new Promise((resolve, reject) => {
    switch(language){
      case 'pl':
        collection = {
          ...collection, 
          updateUser: window.localStorage.getItem('IRI'),
          updateDate: new Date()
        };
        API.put('/documents/' + idAPI, collection)
          .then(function (res) {
            console.log(res);
            resolve({
              forState: {...resetState(values), tabKey: 2},
              currentSku: { 
                "className" : "sku", 
                "value" : [ collection.sku.value[0] ], 
                "type" : "one-field-without-ext", 
                "iteration" : 0 
              }
            });
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      case 'gb':
        API.put('/document_g_bs/' + idAPI, collection)
          .then(function (res) {
            console.log(res);
            resolve({ forState: {...resetState(values), tabKey: 2} });
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      case 'fr':
        API.put('/document_f_rs/' + idAPI, collection)
          .then(function (res) {
            console.log(res);
            resolve({forState: { ...resetState(values), tabKey: 2 }});
          })
          .catch(function (error) {
            reject(error);
          });
        break;
      case 'de':
        API.put('/document_d_es/' + idAPI, collection)
          .then(function (res) {
            console.log(res);
            resolve({forState: { ...resetState(values), tabKey: 2 }});
          })
          .catch(function (error) {
            reject(error);
          });
        break;
    }
  });

  return handleUpdatePromise;
}

export default handleUpdateSubmit;