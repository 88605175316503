// ok

import _ from 'lodash';

const getValues = (className, type, values) => {
  return _.head(_.filter(values, el => {
    return el.className === className && el.type === type;
  })).values;
}

export default getValues;