import PrepareDocument from './prepare_document_to_edit';
import updateObjectValuesByClassAndType from './update_object_values_by_class_and_type';
import _ from 'lodash';
import API from '../api';

const onLanguageChange = (language, actualSku, values) => {
  let onLanguageChangePromise = new Promise((resolve) => {
  
    let objForState = {};
    const setObjForState = (obj) => {
      objForState = {...objForState, ...obj};
    };

    let prepare = (documentResAPI) => {
      let documentAPI = _.head(documentResAPI.data['hydra:member']);
      if(!_.isEmpty(documentAPI)){
        
        let preparedDocument = PrepareDocument(documentAPI);
        _.forEach(preparedDocument, el => {
          setObjForState(updateObjectValuesByClassAndType(el[0], el[1], el[2], values));
        });
        let docId = documentAPI.id;

        if(docId){
          
          switch(language){
            case 'pl':
              setObjForState({
                skuLocked: false, 
                language: 'pl', 
                tabKey: 1, 
                documentId: docId,
                updateButtonDisabled: false, 
                titleLengthMessageWasShown: true
              });
              break;
            case 'gb':
              setObjForState({
                language: 'gb', 
                tabKey: 1, 
                documentIdGB: docId,
                updateButtonDisabled: false, 
                titleLengthMessageWasShown: true 
              });
              break;
            case 'fr':
              setObjForState({
                language: 'fr', 
                tabKey: 1, 
                documentIdFR: docId,
                updateButtonDisabled: false, 
                titleLengthMessageWasShown: true
              });
              break;
            case 'de':
              setObjForState({
                language: 'de', 
                tabKey: 1, 
                documentIdDE: docId,
                updateButtonDisabled: false, 
                titleLengthMessageWasShown: true
              });
              break;
          }
        }else{
          setObjForState({
            tabKey: 1, 
            updateButtonDisabled: false
          });
        }
      }else{
        setObjForState({
          buttonClicked: false, 
          updateButtonDisabled: true
        });
      }
    }

    if(language !== 'pl'){
      setObjForState({
        language: language, 
        skuLocked: true
      });
      switch(language){
        case 'gb':
          API.get('/document_g_bs?skuStr=' + actualSku)
            .then(function (res) {
              console.log(res);
              prepare(res);
              resolve(objForState);
            })
            .catch(function (error) {
              console.log(error);
            });
          break;
        case 'fr':
          API.get('/document_f_rs?skuStr=' + actualSku)
            .then(function (res) {
              console.log(res);
              prepare(res);
              resolve(objForState);
            })
            .catch(function (error) {
              console.log(error);
            });
          break;
        case 'de':
          API.get('/document_d_es?skuStr=' + actualSku)
            .then(function (res) {
              console.log(res);
              prepare(res);
              resolve(objForState);
            })
            .catch(function (error) {
              console.log(error);
            });
          break;
      }
    }else{
      setObjForState({
        language: language, 
        skuLocked: false
      });
      API.get('/documents?skuStr=' + actualSku)
        .then(function (res) {
          console.log(res);
          prepare(res);
          resolve(objForState);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  });

  return onLanguageChangePromise;
}

export default onLanguageChange;