import React, {Component} from 'react';
import InputComponent from './input_component';
import AlertContainer from 'react-alert';
import AlertMessages from '../data/alert_messages';
import _ from 'lodash';

class OneFieldModuleConst extends Component {

  constructor(props){
    super(props);
    this.state = { oneFieldInputs: [''] };
    this.addField = this.addField.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.buttonClicked){
      _.times(nextProps.values.length, n => {
        this.setVal({className: this.props.className, value: [nextProps.values[n]], type: this.props.fieldType, iteration: n, label: undefined });
      });
      this.setState({oneFieldInputs: nextProps.values});
    }
  }

  prepareInputs(){
    return _.map([...this.state.oneFieldInputs], (el, index) => {
      return <InputComponent buttonClicked={this.props.buttonClicked} value={[el]} onUpdate={this.setVal.bind(this)} key={`${this.props.className}-${index}`} className={`${this.props.className}`} fieldType={this.props.fieldType} fieldIndex={index} />
    });
  }

  setVal(value){
    this.props.onUpdateVal(value);
  }

  alertOptions = {
    offset: 14,
    position: 'bottom left',
    theme: 'dark',
    time: 5000,
    transition: 'scale'
  }

  addField(){
    const thisElementsInDom = document.getElementsByClassName(this.props.className);
    if(thisElementsInDom[thisElementsInDom.length-1].value === ''){
      this.msg.error(AlertMessages.messages.beforeInputEmpty);
      return;
    }
    let inputs = [...this.state.oneFieldInputs];
    inputs.push('');
    this.setState({ oneFieldInputs: inputs });
  }

  removeField(){
    const inputs = [...this.state.oneFieldInputs];
    if(inputs.length > 1){
      inputs.pop();
      this.setState({ oneFieldInputs: inputs });
      this.props.onDeleteValue(this.props.className, this.props.fieldType);
    }else{
      this.msg.error(AlertMessages.messages.cantRemoveBaseField);
      return;
    }
  }

  removeAllLeaveHeadBlank(){
    this.props.removeAllLeaveHeadBlank(this.props.className, this.props.fieldType);
  }

  render(){
    return (
      <div className='jumbotron'>
        <div className='row'>
          <div className={`row ${this.props.className}`}>
            <label>{this.props.labelName}</label>
            <div>{this.prepareInputs()}</div>
            <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
            <button type="button" onClick={this.addField} className={`btn btn-success btn-add-${this.props.className}`}>
              <i className="glyphicon glyphicon-plus"></i>
            </button>
            <button type="button" onClick={this.removeField.bind(this)} className={`btn btn-danger btn-remove-${this.props.className}`}>
              <i className="glyphicon glyphicon-minus"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default OneFieldModuleConst;
