import React, {Component} from 'react';

class RadioConst extends Component {
  constructor(props){
    super(props);
    this.state = { radioValue: '' };
  }

  render(){
    return (
      <div className={`col-md-${this.props.radioColumns}`}>
        <label htmlFor={this.props.radioPositionName}>{this.props.radioTitle}
          <input onChange={e=>{
            this.props.onUpdate({className: this.props.className, value: e.target.id, type: this.props.fieldType, iteration: 0, label: this.props.label});
            this.setState({radioValue: e.target.id});
          }} checked={this.props.chosenRadio === this.props.radioPositionName} type='radio' id={this.props.radioPositionName} name={this.props.radioGroupName} value={this.props.radioTitle}/>
        </label>
      </div>
    );
  }
};

export default RadioConst;
