import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import API from '../api';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';

class UsersModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedToDestroy: [],
    };
  }

  componentDidMount() {
    this.setState({preloaderShow: true});
    API.get(
      '/app_users'
    ).then(res => {
      let preparedData = res.data['hydra:member'].map(el => {
        return {id: el.id, email: el.email, admin: _.includes(el.roles, 'ROLE_ADMIN')};
      });
      this.props.setUsers(preparedData);
      this.setState({preloaderShow: false});
    })
    .catch((error) => {
      console.log(error);
      this.setState({preloaderShow: false});
    });
  }

  onRowSelect(row, isSelected) {
    let selectedToDestroy = [...this.state.selectedToDestroy];
    let index = _.indexOf(selectedToDestroy, row.id);

    if(isSelected){
      index < 0 && selectedToDestroy.push(row.id);
    }else{
      index > -1 && _.pullAt(selectedToDestroy, index);
    }
    
    this.setState({selectedToDestroy: selectedToDestroy});
  }

  onSelectAll(isSelected, rows) {
    let selectedToDestroy = [...this.state.selectedToDestroy];
    for (let i = 0; i < rows.length; i++) {
      let index = _.indexOf(selectedToDestroy, rows[i].id);
      if(isSelected){
        index < 0 && selectedToDestroy.push(rows[i].id);
      }else{
        index > -1 && _.pullAt(selectedToDestroy, index);
      }
    }
    this.setState({selectedToDestroy: selectedToDestroy});
  }

  removeSelected(){
    confirmAlert({
      title: 'Usuwanie',                        // Title dialog
      message: 'Czy jestes pewny, że chcesz usunąć zaznaczonych użytkowników z bazy danych?',               // Message dialog
      confirmLabel: 'Tak',                           // Text button confirm
      cancelLabel: 'Anuluj',                             // Text button cancel
      onConfirm: () => {
        _.forEach([...this.state.selectedToDestroy], el => {
          let deletePromise = new Promise((resolve, reject) => {
            API.delete(
              '/app_users/' + el
            ).then((res) => {
              console.log(res);
              resolve(el);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
          });

          deletePromise.then(el => {            
            let filteredUsers = [...this.props.users].filter(function(value){
              return value.id !== el;
            });
            
            this.props.setUsers(filteredUsers);
            this.setState({selectedToDestroy: []});
            this.refs.users.cleanSelected();
          }).catch(error => {
            console.log(error);
          });
          
        });
      }
    });
  }

  displayPreloader() {
    if(this.state.preloaderShow){
      return(
        <div className='preloader-screen'>
            <Loader
              type="Oval"
              color="#00BFFF"
              height="100"
              width="100"
            />
        </div>
      );
    }
  }

  render() {
    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: this.onRowSelect.bind(this),
      onSelectAll: this.onSelectAll.bind(this)
    };

    return (
      <div className='jumbotron admin-table'>
        {this.displayPreloader()}
        <button onClick={e => this.removeSelected()} className='btn btn-danger btn-remove-selected-cars'>Usuń zaznaczone</button>
        <BootstrapTable data={ this.props.users } selectRow={ selectRowProp } ref='users' pagination>
          <TableHeaderColumn dataField='id' isKey={ true } >ID</TableHeaderColumn>
          <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
          <TableHeaderColumn dataField='admin'>Admin</TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setUsers: users => dispatch({type: actionTypes.SET_USERS, users: users}),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersModule);